.rs-button {
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--btn-gap, 8px);
  padding: var(--btn-padding, 8px 16px);
  color: var(--text-idle);
  background: var(--grey-900);
  border-color: var(--grey-900);
  height: auto;

  &:not(.ant-btn-icon-only)>.ant-btn-icon.ant-btn-loading-icon,
  &:not(.ant-btn-icon-only)>.ant-btn-icon:not(:last-child) {
    margin-inline-end: 0;
  }
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--text-idle);
    background: var(--grey-800);
    border-color: var(--grey-800);
  }
  &:disabled,
  &.ant-btn-disabled {
    color: var(--text-idle);
    background: var(--grey-900);
    border-color: var(--grey-900);
  }
  &.default-outlined {
    // background: var(--grey-800);
    border-color: var(--grey-800);
  }
  &.light-outlined {
    color: var(--text-light);
    border-color: var(--text-light);
    background: var(--grey-800);

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--grey-800);
      background: var(--text-light);
      border-color: var(--text-light);
    }
  }
  &.ant-btn-primary {
    border: none;
    color: var(--text-dark);
    background: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%);

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--text-dark);
      background: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%);
    }
    &.primary-outlined {
      border: thin solid var(--text-active);
      color: var(--text-active);
      background: var(--grey-800);
    }
  }
  &.danger {
    color: var(--text-light);
    background: var(--text-alert);

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--text-light);
      background: var(--text-alert);
    }
    &-outlined {
      color: var(--text-alert-base);
      border-color: var(--text-alert-base);
      background: var(--grey-800);

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: var(--text-light);
        background: var(--text-alert-base);
        border-color: var(--text-alert-base);
      }
    }
  }
  &.success {
    color: var(--text-light);
    background: var(--text-success);

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--text-light);
      background: var(--text-success);
    }
    &-outlined {
      color: var(--text-success-base);
      border-color: var(--text-success-base);
      background: var(--grey-800);

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: var(--text-light);
        background: var(--text-success-base);
        border-color: var(--text-success-base);
      }
    }
  }
  &.row-reverse {
    flex-direction: row-reverse;
  }
  &.size-sm {
    font-size: 12px;
    line-height: 18px;
    padding: 4px 8px;
  }
}

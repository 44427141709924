@include vwConvert() using ($s) {
  .rs-radio {
    height: vw(32, $s);
    padding-inline: vw(4, $s);
    gap: vw(8, $s);
    font-size: vw(14, $s);

    .icon {
      margin-inline-end: vw(8, $s);
    }
    &.ant-radio-button-wrapper {
      line-height: vw(32, $s);
      border-width: vw(1, $s);

      &:first-child {
        border-start-start-radius: vw(8, $s);
        border-end-start-radius: vw(8, $s);
      }
      &:last-child {
        border-start-end-radius: vw(8, $s);
        border-end-end-radius: vw(8, $s);
      }
    }
    &--button {
      padding: vw(12, $s);
      border-radius: vw(12, $s);
      border-inline-start-width: vw(1, $s);
      line-height: vw(22, $s);

      &:not(:first-child) {
        &::before {
          display: none;
        }
      }
      &.ant-radio-button-wrapper {
        &:not(:first-child) {
          border-inline-start-width: vw(1, $s) !important;
        }
        &:first-child {
          border-start-start-radius: vw(12, $s);
          border-end-start-radius: vw(12, $s);
        }
        &:last-child {
          border-start-end-radius: vw(12, $s);
          border-end-end-radius: vw(12, $s);
        }
      }
      &.vertical {
        height: vw(72, $s);
        padding: vw(8, $s);

        // span {
        //   gap: vw(4, $s);
        // }
        img {
          height: vw(32, $s);
        }
      }
      .anticon {
        margin-inline-end: vw(12, $s);
        font-size: vw(24, $s);
      }
    }
    &-group {
      &--button {
        gap: vw(8, $s);
      }
    }
  }
}

.rs-radio {
  font-family: inherit;
  color: var(--neutral-gray-700);
  text-align: center;

  &--button {
    text-align: start;
    height: auto;

    span {
      display: flex;
      align-items: center;
    }

    &.vertical {
      span {
        flex-direction: column;
      }
      img {
        aspect-ratio: 1;
      }
      .active {
        display: none;
      }
      .un-active {
        display: block;
      }
    }
  }
  &-group {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
  &.ant-radio-button-wrapper {
    &:hover,
    &-checked span {
      color: var(--primary-6);
    }
    &:not(:first-child) {
      border-inline-start-width: 0;
    }
    &-checked {
      .active {
        display: block;
      }
      .un-active {
        display: none;
      }
    }
  }
  .icon {
    vertical-align: middle;
  }
  &.volcano {
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--volcano-6);
      background-color: var(--volcano-1);
    }
    &.ant-radio-button-wrapper:hover,
    &.ant-radio-button-wrapper-checked span {
      color: var(--volcano-6);
    }
  }
  &.primary-5 {
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--primary-5);
      background-color: var(--primary-1);
    }
    &.ant-radio-button-wrapper:hover,
    &.ant-radio-button-wrapper-checked span {
      color: var(--primary-5);
    }
  }
  &.golden-purple {
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--golden-purple-6);
      background-color: var(--golden-purple-1);
    }
    &.ant-radio-button-wrapper:hover,
    &.ant-radio-button-wrapper-checked span {
      color: var(--golden-purple-6);
    }
  }
  &.magenta {
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: var(--magenta-6);
      background-color: var(--magenta-1);
    }
    &.ant-radio-button-wrapper:hover,
    &.ant-radio-button-wrapper-checked span {
      color: var(--magenta-6);
    }
  }
}

// CUSTOM RADIO
.custom-radio {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  color: var(--text-light);
  background: var(--grey-900);
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;

  input {
    position: absolute;
    z-index: -9;
    pointer-events: none;

    &:checked {
      ~ .custom-radio-icon {
        border: 3px solid var(--text-active);
      }
    }
  }
  &-icon {
    display: inline-flex;
    border-radius: 50%;
    background: var(--grey-50);
    height: 16px;
    aspect-ratio: 1;
    margin-inline-start: auto;
  }
}

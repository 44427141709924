.game-layout {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  gap: 24px;
  min-height: 700px;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 322px 436px 322px;
    justify-content: space-between;
    overflow: hidden;
  }
}

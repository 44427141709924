.rs-drawer {
  font-family: inherit;
  line-height: inherit;
  color: inherit;

  .ant-drawer {
    &-header {
      padding: 16px;
    }
    &-title {
      color: var(--text-idle);
      font-size: 18px;
      font-weight: 700;
    }
    &-close {
      margin-inline-end: 0;
      order: 100;
      margin-inline-start: auto;
      padding: 0;
      height: auto;
      width: auto;
    }
    &-content {
      background: #000000;
    }
    &-body {
      padding-block: 0;
    }
    &-footer {
      gap: 16px;
      padding: 16px;
    }
    &-mask {
      background: rgba(0, 0, 0, .5);
      backdrop-filter: blur(10px);
    }
  }
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-picker {
    padding: 10px 16px;
    font-size: 15px;
  }
  .ant-picker {
    padding-block: 11px;
  }
  .ant-input-affix-wrapper {
    .ant-input-prefix {
      margin-inline-end: 8px;
    }
  }
  .avatar-uploader {
    .ant-upload {
      &-wrapper {
        width: auto;
        display: inline-flex;
      }
    }
    .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
    .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
      height: 80px;
      width: 80px;
      border-style: solid;
      background-color: #F3F4F6;
    }
  }
  &.w-338 {
    .ant-drawer-content-wrapper {
      width: 338px !important;
    }
  }
  &.footer-grey-900 {
    .ant-drawer-footer {
      padding: 12px 24px;
      background: var(--grey-900);
    }
  }
  &.footer-with-2-button {
    .ant-drawer-footer {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-columns: 1fr;
    }
  }
}

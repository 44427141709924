.payment-method-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
  gap: 18px;
  @media screen and ( max-width: 376px ) {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  }
}
.ovh-y-all-card{
  height: 48vh;
  overflow-y: auto;
}

@import './styles/reset.scss';
@import './styles/root.scss';
@import './assets/styles/styles.scss';
@import './styles/custom.scss';

body {
  font-family: var(--font-regular);
  color: var(--neutral-gray-700);
  background-color: #000;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
}
.shift-content .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .text::before,
.tab-match-rules .ant-collapse .ant-collapse-item.ant-collapse-item-active .title-collapse::before,
.custom-radio input:checked ~ .custom-radio-icon{
  border: 5px solid #ffbf00 !important;
}

@import '../../styles/root.scss';

.mw-474 {
  max-width: 474px;
  width: 100%;
}

.mw-502 {
  max-width: 502px;
  width: 100%;
}

.mw-708 {
  max-width: 708px;
  width: 100%;
}

.mw-586 {
  max-width: 586px;
  width: 100%;
}

.mw-801 {
  max-width: 801px;
  width: 100%;
}

.h-68 {
  height: 68px !important;
}

.mw-1216 {
  max-width: 1216px;
  width: 100%;
}

.cl-F9D361 {
  color: #F9D361 !important;
}

.mt-24 {
  margin-top: 24px;
}

.checkin-page {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  min-height: 1080px;
  &.checkin-ai-progress {
    .bg-img {
      top: 215px;
      left: 1029px;
      z-index: 3;
      width: 650px;
      height: 650px;
    }

    .bg-img-checkin-progress-ellipse {
      z-index: 1;
      background: transparent;
    }
  }

  .content-block {
    margin-left: 240px;
    position: relative;
    z-index: 2;
    .title {
      font-size: 80px;
      line-height: 108px;
      letter-spacing: -0.02px;
      font-weight: 700;
      margin-bottom: 12px;
    }
    .sum {
      color: #F9D361;
      font-size: 40px;
      line-height: 54px;
      letter-spacing: -0.02px;
      margin-bottom: 64px;
    }
    .btn-block{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
      &.ctrl-grid-2 {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .btn {
      width: 100%;
      height: 80px;
      border-radius: 8px;
      border: 3px solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02px;
      font-weight: 700;
      color: #ffffff;

      &.t-btn-primary {
        background: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%);
        border: none;
        color: #080808;
      }

      &.btn-border-text-primary {
        color: #F9D361;
        border-color: #F9D361;
        border-width: 1px;
      }
    }
  }
  .bg-img {
    position: absolute;
    top: 52px;
    left: 727px;
    z-index: 1;
    width: 1301px;
    height: 975px;
  }
  .link-dashboard {
    width: 134px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 12px;
    position: absolute;
    top: 48px;
    right: 48px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    background-color: #262626;
    justify-content: center;
    padding: 0 8px;
    z-index: 2;
    cursor: pointer;
    .icon-svg  {
      display: flex;
      align-items: center;
    }
  }

  .fz-80 {
    font-size: 80px;
    line-height: 108px;
    letter-spacing: -0.02px;
    font-weight: 700;
  }

  .fz-24 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02px;
  }

  .ctrl-group-gender {
    margin-bottom: 64px;
    .label {
      font-size: 16px;
      line-height: 22px;
      color: #999;
      margin-bottom: 10px;
    }
  }

  .form-block {
    .ant-form-item {
      margin-bottom: 0;
      label {
        font-size: 16px;
        line-height: 22px;
        color: #999;
        margin-bottom: 2px;
        &::before {
          display: none !important;
        }
      }

      .ant-input-outlined {
        border-top: none;
        border-left: none;
        border-right: none;
        // border-bottom: 1px solid #999999;
        border-radius: 0;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02px;
        padding: 0;
        padding-bottom: 18px;
        color: #fff;
        background-color: transparent;
        &::placeholder {
          color: #999;
        }
        &:focus, &:hover {
          border-color: #F9D361;
          box-shadow: none;
        }
      }

      .input-block input:not(:placeholder-shown) {
        border-color: #F9D361;
      }

      .ant-form-item-explain-connected {
        display: none;
      }
    }

    .btn-block {
      margin-top: 64px;
    }

    .mb-0 {
      margin-bottom: 0 !important;
    }
  }

  .mb-24 {
    margin-bottom: 24px !important;
  }

  .note-block {
    font-size: 32px;
    line-height: 43px;
    letter-spacing: -0.4px;
    span {
      color: #F9D361;
    }
  }

  .t-col-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
  }

  .t-col-6 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 24px;
  }

  .ant-form-item-label {
    padding-top: 18px;
  }

  .group-gender {
    display: flex;
    gap: 18px;
    .one-gender {
      height: 68px;
      padding: 0 32px;
      border-radius: 8px;
      color: #ffffff;
      border: 1px solid #ffffff;
      display: flex;
      gap: 12px;
      align-items: center;
      cursor: pointer;
      &.active {
        color: #F9D361;
        border-color: #F9D361;
        border-width: 1px;
        .icon-svg {
          color: #F9D361;
        }
      }
      .icon-svg {
        font-size: 32px;
        color: #ffffff;
      }
      .text {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02px;
      }
    }
  }

  .list-setup {
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    .one-item {
      display: flex;
      gap: 24px;
      align-items: center;
      .number {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #F9D361;
        color: #F9D361;
        font-size: 32px;
        line-height: 43px;
        .icon-svg {
          display: flex;
          align-items: center;
        }
      }
      .text {
        font-size: 32px;
        line-height: 43px;
        letter-spacing: -0.4px;
      }
    }
  }

  .cl-fff {
    color: #fff !important;
  }

  .bg-img-checkin-progress {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      z-index: 2;
    }
    video {
      position: relative;
      z-index: 1;
    }
  }

  .bg-img-checkin-progress-ellipse {
    position: absolute;
    top: 153px;
    left: 1045px;
    width: 870px;
    height: 870px;
    background: radial-gradient(50% 50% at 50% 50%, #F9D361 0%, #080808 100%);
    // img {
    //   height: 100%;
    // }
  }

  .list-loading {
    display: flex;
    gap: 18px;
    flex-direction: column;
    .one-item {
      &.checkin-loading {
        .text {
          color: #fff;
        }
      }
      .text {
        color: #c0b18b;
      }
    }
    .op-40 {
      opacity: 0.4;
    }
  }

  .mw-850 {
    max-width: 850px;
    width: 100%;
  }

  .mw-1157 {
    max-width: 1157px;
    width: 100%;
  }

  &.checkin-notification {
    .bg-img {
      width: 1082px;
      height: 1082px;
      left: 510px;
      top: 0;
      img {
        height: 100%;
      }
    }
    .content-block {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .btn {
      padding: 0 48px;
      display: inline-flex;
      width: auto;
      min-width: 281px;
    }
  }

  &.checkin-packages {
    padding-bottom: 100px;
    .bg-img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 485px;
      height: 582px;
      top: auto;
      left: auto;
    }
    .content-block {
      margin-left: auto;
      position: relative;
      z-index: 2;
      margin-right: auto;
      margin-top: 100px;
      .info-user {
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .avata {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          overflow: hidden;
          border: 5px solid #FFE279;
          margin-bottom: 24px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .id-user {
          margin-bottom: 12px;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.02px;
        }
        .title {
          span {
            color: #F9D361;
          }
        }
      }

      .list-packages {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 64px;
        .one-item {
          position: relative;
          padding: 90px 62px;
          min-height: 455px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .img-bg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            pointer-events: none;
          }
          i {
            line-height: 1;
          }
          .text {
            margin-top: 18px;
          }
          &.packages-d {
            i {
              background-image: linear-gradient(97.83deg, #F2A142 -2.91%, #F2C182 43.14%, #FFC979 101.74%);
              -webkit-background-clip: text;
              /* Cho các trình duyệt WebKit */
              -webkit-text-fill-color: transparent;
              /* Cho các trình duyệt WebKit */
              background-clip: text;
              color: transparent;
              padding-right: 10px;
            }
            .btn {
              background: linear-gradient(97.83deg, #F2A142 -2.91%, #FBD6A8 43.14%, #FFC979 101.74%);
              color: #0F0F0F;
              border: none;
            }
          }

          &.packages-v {
            i {
              background-image: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%);
              -webkit-background-clip: text;
              /* Cho các trình duyệt WebKit */
              -webkit-text-fill-color: transparent;
              /* Cho các trình duyệt WebKit */
              background-clip: text;
              color: transparent;
              padding-right: 10px;
            }

            .btn {
              background: linear-gradient(97.83deg, #FAD664 -2.91%, #FFEA9D 60.93%, #F2C142 101.74%);
              color: #0F0F0F;
              border: none;
            }
          }

          &.packages-b {
            i {
              background-image: linear-gradient(97.83deg, #929292 -2.91%, #FFFFFF 101.74%);
              -webkit-background-clip: text;
              /* Cho các trình duyệt WebKit */
              -webkit-text-fill-color: transparent;
              /* Cho các trình duyệt WebKit */
              background-clip: text;
              color: transparent;
              padding-right: 10px;
            }

            .btn {
              background: linear-gradient(97.83deg, #929292 -2.91%, #FFFFFF 57.27%, #9F9F9F 101.74%);
              color: #0F0F0F;
              border: none;
            }
          }
        }
      }
    }
  }

  &.buy-success {
    .content-block {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .img-box {
      width: 586px;
    }
    .bg-img {
      width: 1082px;
      height: 1082px;
      left: 510px;
      top: 0;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  &.checkin-chart {
    .content-block {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      .title-db-big {
        text-align: center;
      }
      .title-db-sub {
        padding-right: 3px;
        background-image: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%);
        -webkit-background-clip: text;
        /* Cho các trình duyệt WebKit */
        -webkit-text-fill-color: transparent;
        /* Cho các trình duyệt WebKit */
        background-clip: text;
        color: transparent;
        font-size: 48px;
        line-height: 1.2;
        font-weight: 900;
        font-family: var(--font-bold);
        text-align: center;
        display: block;
        margin-top: -44px;
        margin-bottom: 24px;
      }

      .list-filter-block {
        display: flex;
        gap: 24px;
        margin-bottom: 24px;
        font-weight: 500;
        .list-filter-date {
          display: flex;
          gap: 12px;
          .one-item {
            min-width: 88px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 1px 2px 0px #1018280D;
            color: #999999;
            border-radius: 8px;
            background-color: #080808;
            cursor: pointer;
            &.active {
              background-color: #0F0F0F;
              color: #fff;
            }
          }
        }
        .center {
          display: flex;
          align-items: center;
          gap: 12px;
        }
        .right {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          color: #999;
          font-size: 14px;
          line-height: 19px;
          text-align: right;
        }
        .t-ctrl-select-date-2icon {
          position: relative;
          background-color: #0F0F0F;
          min-width: 88px;
          height: 40px;
          border-radius: 8px;
          max-width: 175px;
          input {
            &::placeholder {
              color: #999;
            }
          }
          .icon-svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            pointer-events: none;
            &.icon-left {
              left: 16px;
            }
            &.icon-right {
              right: 16px;
              width: 16px;
              height: 16px;
              border-radius: 4px;
              background-color: #292D32;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                margin-top: 2px;
              }
            }
          }
          .ant-select {
            height: 100%;
          }

          .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            padding-inline-end: 0;
            color: #999;
          }

          .ant-picker-suffix, .ant-picker-clear {
            display: none;
          }

          .ant-picker-outlined, .ant-select-selector {
            background: transparent;
            border-color: transparent;
            height: 100%;
            padding-left: 44px;
            padding-right: 44px;
          }
          .ant-picker-input {
            height: 100%;
            color: #999999;
          }

          .ant-select-arrow {
            display: none;
          }
        }
      }

      .chart-show {
        display: flex;
        .left {
          max-width: 394px;
          width: 100%;
          flex: 0 0 auto;
        }

        .right {
          flex-grow: 1;
          padding-left: 24px;
          #chart {
            padding: 20px 24px 8px 12px;
            border-radius: 8px;
            background-color: #0F0F0F;
            box-shadow: 0px 1px 3px 0px #1018281A;
            color: #000;
            .apexcharts-toolbar {
              display: none;
            }
          }
        }

        .list-show {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
          width: 100%;
          .one-item {
            border-radius: 8px;
            padding: 24px;
            gap: 18px;
            box-shadow: 0px 1px 3px 0px #1018281A;
            background-color: #0F0F0F;

            .title {
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              margin-bottom: 18px;
              color: #999999;
            }
            .number {
              font-size: #E5E5E5;
              font-size: 24px;
              font-weight: 700;
              line-height: 1;
              margin-bottom: 16px;
              &.fz-32 {
                font-size: 32px;
              }
            }
            .compare {
              display: flex;
              gap: 8px;
              align-items: center;
              .percent-block {
                display: flex;
                gap: 4px;
                color: #B42318;
                &.cl-027A48 {
                  color: #027A48;
                  .icon-svg {
                    color: #12B76A;
                  }
                }
              }
              .icon-svg {
                font-size: 16px;
                color: #F04438;
              }
              .text {
                color: #999999;
              }
            }
          }
        }
      }

      .pie-chart-block {
        padding: 24px;
        box-shadow: 0px 1px 3px 0px #1018281A;
        background-color: #0F0F0F;
        margin-top: 32px;
        border-radius: 8px;
        display: flex;

        .left {
          max-width: 394px;
          width: 100%;
          flex: 0 0 auto;
        }

        .right {
          flex-grow: 1;
          display: grid;
          gap: 118px;
          grid-template-columns: repeat(2, 1fr);
          .arc-card {
            padding: 0;
            background: transparent;
          }
          .arc-card__content {
            width: 100%;
            .recharts-wrapper {
              max-width: 100% !important;
            }
            .recharts-legend-wrapper {
              top: 50% !important;
              transform: translateY(-50%) !important;
              left: 61%;
              right: auto !important;
            }
            .recharts-legend-item {
              display: flex !important;
              align-items: flex-start;
              gap: 8px;
              margin-right: 0 !important;
              width: 100%;

              .recharts-surface {
                width: 10px;
                height: 10px;
                margin-right: 0 !important;
                flex: 0 0 auto;
                max-width: 10px;
                width: 100%;
                margin-top: 4px;
              }
            }
            .recharts-legend-item-text {
              font-size: 12px;
              color: #999999;
              .neutral-gray-400 {
                color: #FFFFFF;
              }
            }
            .recharts-default-legend {
              width: 150px !important;
            }
            .statistical-pie-chart {
              border: none;
            }
          }
        }

        .list-show {
          .one-item {
            .title {
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              margin-bottom: 18px;
              color: #999999;
            }

            .number {
              font-size: #E5E5E5;
              font-size: 24px;
              font-weight: 700;
              line-height: 1;
              margin-bottom: 16px;

              &.fz-32 {
                font-size: 32px;
              }
            }

            .compare {
              display: flex;
              gap: 8px;
              align-items: center;

              .percent-block {
                display: flex;
                gap: 4px;
                color: #B42318;

                &.cl-027A48 {
                  color: #027A48;

                  .icon-svg {
                    color: #12B76A;
                  }
                }
              }

              .icon-svg {
                font-size: 16px;
                color: #F04438;
              }

              .text {
                color: #999999;
              }
            }
          }
        }
      }
    }

    .btn-back {
      width: 77px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid #999;
      color: #999;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
      position: absolute;
      top: 16px;
      left: 0;
    }
  }
}

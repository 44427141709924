@include vwConvert() using ($s) {
  .rs-tag {
    padding-inline: vw(8, $s);
    border-radius: vw(8, $s);
    font-size: vw(12, $s);
    line-height: vw(20, $s);
    border-width: vw(1, $s);
    gap: vw(4, $s);

    .anticon {
      font-size: vw(16, $s);
    }
    &.daybreak-blue {
      font-size: vw(14, $s);
    }
    &.neutral-gray {
      box-shadow: 0px vw(-1, $s) 0px 0px #F0F0F0 inset;
      border-radius: vw(100, $s);

      &::before {
        box-shadow: 0px vw(2, $s) 0px 0px #00000004;
      }
    }
  }
  .ant-tags {
    gap: vw(8, $s);
  }
}

.rs-tag {
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  margin-inline-end: 0;

  &.geek-blue {
    color: var(--geek-blue-6);
    background-color: var(--geek-blue-1);
    border-color: var(--geek-blue-3);
  }
  &.golden-purple {
    color: var(--golden-purple-6);
    background-color: var(--golden-purple-1);
    border-color: var(--golden-purple-3);
  }
  &.daybreak-blue {
    color: var(--primary-7);
    background-color: var(--primary-1);
    border-color: var(--primary-3);

    &.no-border {
      border-color: var(--primary-1);
    }
    &--outlined {
      border-style: solid;
      color: var(--primary-7);
      background-color: #FFFFFF;
      border-color: var(--primary-7);
    }
  }
  &.neutral-gray {
    color: var(--neutral-gray-500);
    background-color: #FFFFFF;
    border: none;
    position: relative;

    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      inset: 0 0 0 0;
    }
  }
  &.sunset-orange {
    color: var(--sunset-orange-6);
    background-color: var(--sunset-orange-1);
    border-color: var(--sunset-orange-3);
  }
  &.dust-red {
    color: var(--dust-red-6);
    background-color: var(--dust-red-1);
    border-color: var(--dust-red-3);
  }
  &.success {
    color: var(--success-500);
    background-color: var(--success-25);
    border-color: var(--success-500);
  }
  &.polar-green {
    color: var(--polar-green-6);
    background-color: var(--polar-green-1);
    border-color: var(--polar-green-3);
  }
  &.volcano {
    color: var(--volcano-6);
    background-color: var(--volcano-1);
    border-color: var(--volcano-6);
  }
  &.magenta {
    color: var(--magenta-6);
    background-color: var(--magenta-1);
    border-color: var(--magenta-3);
  }
  > .anticon + span,
  > span + .anticon {
    margin: 0;
  }
}
.ant-tags {
  display: flex;
  flex-wrap: wrap;
}

@import './reset/button';
@import './reset/switch';
@import './reset/tab';
@import './reset/tag';
@import './reset/progress';
@import './reset/date-picker';
@import './reset/range-picker';
@import './reset/pagination';
@import './reset/badge';
@import './reset/search';
@import './reset/select';
@import './reset/timeline';
@import './reset/input';
@import './reset/form';
@import './reset/radio';
@import './reset/checkbox';
@import './reset/table';
@import './reset/modal';
@import './reset/notification';
@import './reset/message';
@import './reset/alert';
@import './reset/drawer';
@import './reset/step';

.rs-empty {
  color: var(--text-idle);

  .ant-empty-description {
    color: inherit;
  }
}

@include vwConvert() using ($s) {
  .rs-recharts {
    &-legend {
      .recharts {
        &-text {
          font-size: vw(12, $s);
        }
        &-legend-item {
          margin-inline-end: vw(12, $s) !important;
          
          svg {
            margin-inline-end: vw(4, $s) !important;
            width: vw(8, $s);
            height: vw(8, $s);
          }
        }
      }
    }
    &-tooltip {
      .recharts-default-tooltip {
        padding: vw(10, $s) !important;
        border-width: vw(1, $s) !important;
      }
      .recharts-tooltip-item {
        padding-block: vw(4, $s) !important;
      }
    }
  }
}

.rs-recharts {
  .recharts-surface {
    overflow: visible;
  }
  &-legend {
    .recharts {
      &-legend-item {
        &:last-child {
          margin-inline-end: 0 !important;
        }
      }
    }
  }
}
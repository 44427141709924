.game-empty-result {
  padding-block: clamp(40px, 6.67vw, 80px);
  padding-inline: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

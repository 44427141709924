.game-button {
  height: 50px;
  width: 150px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 8px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  user-select: none;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;
    pointer-events: none;
  }
  &::before {
    border: 2px solid transparent; /*2*/
    background: linear-gradient(180deg, rgba(249, 211, 97, 0) 0%, #F9D361 27.39%, #C9A661 44.71%, #F9D361 54.17%, rgba(249, 211, 97, 0) 100%) border-box; /*3*/
    mask: /*4*/
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask: /*4*/
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
      mask-composite: exclude; /*5*/
  }
  &::after {
    background: radial-gradient(50% 50% at 50% 50%, rgba(177, 177, 177, 0) 47.4%, rgba(177, 177, 177, 0.08) 100%);
  }
  &.disabled {
    cursor: not-allowed;

  }
}

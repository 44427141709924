.rs-select {
  height: auto;
  font-family: inherit;
  font-size: inherit;

  &-dropdown {
    box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
    border: 1px solid #F2F4F7;
    background: var(--grey-900);
    font-size: 16px;
    padding-inline: 0;

    .select-all {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--text-idle);

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 24px;
        aspect-ratio: 1;
        flex-shrink: 0;
        border: 1px dashed var(--text-idle);
        font-size: 16px;
      }
    }
    .ant-select-item {
      color: var(--text-idle);
      padding: 10px 14px;
      gap: 8px;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: var(--text-light);
      background: var(--grey-800);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
      color: var(--text-active);
    }
    .ant-empty-normal .ant-empty-description {
      color: var(--text-idle);
    }
  }
  &.ant-select-outlined .ant-select-selector {
    border-color: var(--grey-900);
    background-color: var(--grey-900);
    color: var(--text-idle);
    padding-inline: 16px;
    padding-block: 4px;
  }
  // &.ant-select-outlined:not(.ant-select-disabled):not(.ant-pagination-size-changer):hover .ant-select-selector {
  //   border-color: rgba(0, 0, 0, 0.95);
  //   background-color: rgba(0, 0, 0, 0.95);
  // }
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 28px;
  }
  .ant-select-arrow,
  .ant-select-clear {
    color: var(--text-idle);
    font-size: 14px;
    width: 20px;

    &:hover {
      color: var(--text-light);
    }
  }
  .ant-select-selection-placeholder {
    color: var(--text-idle);
  }
  &.ant-select-single.ant-select-open .ant-select-selection-item {
    color: var(--grey-600);
  }
  &.ant-select-multiple .ant-select-selection-item {
    background: var(--grey-700);
    margin-inline-end: 10px;
    color: var(--text-light);

    &-remove {
      color: inherit;
    }
  }
  &.ant-select-disabled.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item {
    color: var(--text-light);
  }
  &.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--text-alert);
    background: none;

    &:hover,
    &:focus {
      border-color: var(--text-alert-base);
      background: none;
    }
  }
}

.app-breadcrumb {
  &-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-block-end: 24px;

    .ant-breadcrumb {
      color: var(--text-idle);
      font-family: inherit;
      font-size: inherit;
      line-height: 1;

      &-separator {
        margin: 0;
        color: inherit;
      }

      a {
        height: auto;
        padding: 0;
        margin: 0;
        color: var(--text-idle);

        &:hover {
          background: transparent;
          color: #F9D361;
        }
      }
      ol {
        gap: 16px;
        align-items: center;
      }
      li {
        &:last-child {
          color: var(--text-idle);
        }
      }
    }
  }

  &-title {
    display: flex;
    align-items: center;
    gap: 12px;

    .title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      gap: 16px;

      .anticon {
        font-size: inherit;
      }
    }

    .description {
      color: var(--text-idle);
      font-size: 14px;
      margin-inline-start: -4px;
    }

    .back {
      padding-inline: 20px;
      border-color: var(--text-idle);
      font-size: 16px;
      font-family: var(--font-bold);

      &:not(:hover) {
        background: transparent;
      }
    }
  }
}

.breadcrumb-detail-block {
  .btn-back {
    height: 38px;
    width: 77px;
    border: 1px solid #999999;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    margin-right: 8px;
  }
}

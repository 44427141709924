.report-dashboard-content {
  .title-db-big {
    text-align: center;
  }
  .title-db-sub {
    padding-right: 3px;
    background-image: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%);
    -webkit-background-clip: text;
    /* Cho các trình duyệt WebKit */
    -webkit-text-fill-color: transparent;
    /* Cho các trình duyệt WebKit */
    background-clip: text;
    color: transparent;
    font-size: 48px;
    line-height: 1.2;
    font-weight: 900;
    font-family: var(--font-bold);
    text-align: center;
    display: block;
    margin-top: -44px;
    margin-bottom: 24px;
  }

  .list-filter-block {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    font-weight: 500;

    .list-filter-date {
      display: flex;
      align-items: center;
      gap: 12px;

      .one-item {
        min-width: 88px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 1px 2px 0px #1018280D;
        color: #999999;
        border-radius: 8px;
        background-color: #080808;
        cursor: pointer;

        &.active {
          background-color: #0F0F0F;
          color: #fff;
        }
      }
    }
    .center {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .right {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #999;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      gap: 12px;
    }
    .t-ctrl-select-date-2icon {
      position: relative;
      background-color: #0F0F0F;
      min-width: 88px;
      height: 40px;
      border-radius: 8px;
      max-width: 175px;
      input {
        &::placeholder {
          color: #999;
        }
      }
      .icon-svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        pointer-events: none;
        &.icon-left {
          left: 16px;
        }
        &.icon-right {
          right: 16px;
          width: 16px;
          height: 16px;
          border-radius: 4px;
          background-color: #292D32;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-top: 2px;
          }
        }
      }
      .ant-select {
        height: 100%;
      }

      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-inline-end: 0;
        color: #999;
      }

      .ant-picker-suffix, .ant-picker-clear {
        display: none;
      }

      .ant-picker-outlined, .ant-select-selector {
        background: transparent;
        border-color: transparent;
        height: 100%;
        padding-left: 44px;
        padding-right: 44px;
      }
      .ant-picker-input {
        height: 100%;
        color: #999999;
      }

      .ant-select-arrow {
        display: none;
      }
    }
  }

  .chart-show {
    display: flex;
    flex-direction: column;
    gap: 26px;

    &:not(:last-child) {
      margin-block-end: 26px;
    }
    .left {
      .filter {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(68px, 1fr));
        grid-auto-columns: 1fr;
        gap: 8px;

        &-item {
          color: var(--text-idle);
          height: 40px;
          padding: 4px 8px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          border-radius: 8px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.active {
            color: var(--text-light);
            background: var(--grey-900);
            box-shadow: 0px 1px 2px 0px #1018280D;
          }
        }
      }
    }

    .right {
      .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
      }
    }

    .list-show {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
      width: 100%;

      .metric-item,
      .one-item {
        border-radius: 8px;
        padding: 24px 16px;
        gap: 18px;
        box-shadow: 0px 1px 3px 0px #1018281A;
        background-color: #0F0F0F;

        .title {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 18px;
          color: #999999;
        }
        .number {
          font-size: #E5E5E5;
          font-size: 24px;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 16px;
          &.fz-32 {
            font-size: 32px;
          }
        }
        .compare {
          display: flex;
          gap: 8px;
          align-items: center;
          .percent-block {
            display: flex;
            gap: 4px;
            color: #B42318;
            &.cl-027A48 {
              color: #027A48;
              .icon-svg {
                color: #12B76A;
              }
            }
          }
          .icon-svg {
            font-size: 16px;
            color: #F04438;
          }
          .text {
            color: #999999;
          }
        }
      }
      &.metric-list {
        + .metric-list {
          margin-block-start: 24px;
        }
      }
    }

    @media screen and (min-width: 640px) {
      .list-show {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    @media screen and (min-width: 768px) {
      .left {
        .filter {
          grid-template-columns: repeat(auto-fit, minmax(88px, 1fr));
          gap: 12px;

          &-item {
            padding-inline: 16px;
          }
        }
      }
    }
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      gap: 0;

      &:not(:last-child) {
        margin-block-end: 0;
      }
      .left,
      .right {
        .filter {
          margin-block-end: 26px;
        }
      }
      .left {
        max-width: 394px;
        width: 100%;
        flex: 0 0 auto;
      }
      .right {
        flex-grow: 1;
        padding-left: 24px;
      }
    }
  }

  .pie-chart-block {
    padding: 24px;
    box-shadow: 0px 1px 3px 0px #1018281A;
    background-color: #0F0F0F;
    margin-top: 32px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .left {
      // max-width: 394px;
      // flex-grow: 1;

      .list-show {
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      }
    }

    .right {
      flex-grow: 1;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(1, 1fr);
      // grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));

      .arc-card {
        padding: 0;
        background: transparent;
      }
      .arc-card__content {
        width: 100%;

        .recharts-wrapper {
          max-width: 100% !important;
        }
        .recharts-legend-wrapper {
          top: 50% !important;
          transform: translateY(-50%) !important;
          right: 0 !important;
          padding-inline-start: 24px;
        }
        .recharts-legend-item {
          display: flex !important;
          align-items: flex-start;
          gap: 8px;
          margin-right: 0 !important;
          width: 100%;

          .recharts-surface {
            width: 10px;
            height: 10px;
            margin-right: 0 !important;
            flex: 0 0 auto;
            max-width: 10px;
            width: 100%;
            margin-top: 4px;
          }
          .recharts-legend-item-text {
            flex-grow: 1;

            > span {
              display: flex;
              justify-content: space-between;
            }
          }
        }
        .recharts-legend-item-text {
          font-size: 12px;
          color: #999999;
          .neutral-gray-400 {
            color: #FFFFFF;
          }
        }
        .recharts-default-legend {
          width: 136px;
        }
        .statistical-pie-chart {
          border: none;
        }
        // .pie-chart {
        //   &--desktop {
        //     display: none;
        //   }
        // }
        .custom-legend-render {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          gap: 2px;

          &__total {
            font-family: var(--font-medium);
            font-size: 1.2em;
            padding-block-end: 4px;
          }
        }
      }
      .one-chart {
        max-width: 330px;
      }
    }

    .list-show {
      .one-item {
        .title {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 18px;
          color: #999999;
        }

        .number {
          font-size: #E5E5E5;
          font-size: 24px;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 16px;

          &.fz-32 {
            font-size: 32px;
          }
        }

        .compare {
          display: flex;
          gap: 8px;
          align-items: center;

          .percent-block {
            display: flex;
            gap: 4px;
            color: #B42318;

            &.cl-027A48 {
              color: #027A48;

              .icon-svg {
                color: #12B76A;
              }
            }
          }

          .icon-svg {
            font-size: 16px;
            color: #F04438;
          }

          .text {
            color: #999999;
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      .right {
        grid-template-columns: repeat(2, 1fr);
      }
      .arc-card__content {
        .pie-chart {
          &--mobile {
            display: none;
          }
        }
      }
    }
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      gap: 0;

      .left {
        max-width: 394px;
        flex-grow: 1;

        .list-show {
          display: block;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .arc-card__content {
        .pie-chart {
          &--desktop {
            display: none;
          }
        }
      }
    }
  }

  .btn-back {
    width: 77px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #999;
    color: #999;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    position: absolute;
    top: 16px;
    left: 0;
  }
}

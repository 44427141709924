@include vwConvert() using ($s) {
  .rs-alert {
    padding: vw(9, $s) vw(16, $s);
    gap: vw(10, $s);
    border-width: vw(1, $s);
  }
}

.rs-alert {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;

  .ant-alert-icon,
  .ant-alert-close-icon {
    margin-inline: 0;
    font-size: inherit;
    line-height: inherit;
  }
  &.ant-alert-info {
    border-color: var(--daybreak-blue-3);
    background-color: var(--primary-1);

    .ant-alert-icon {
      color: var(--primary-6);
    }
  }
}

.upload-image {
  display: block;
  position: relative;

  input {
    position: absolute;
    inset: 0;
    opacity: 0;
    cursor: pointer;
  }
  &__preview {
    height: var(--preview-size, 80px);
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center center;
      height: 100%;
      width: 100%;
    }
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
      background: rgba(55, 55, 55, 0.75);
      backdrop-filter: blur(4px);
      visibility: hidden;
      border-radius: 50%;
    }
  }
  &__remove {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    visibility: hidden;
    color: var(--text-alert);
  }
  // &:hover {
  //   .upload-image__preview::before {
  //     visibility: visible;
  //   }
  //   .upload-image__remove {
  //     visibility: visible;
  //   }
  // }
  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    height: 80px;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    background-color: #F3F4F6;
  }
}

.staff-manage-content {
  .table-filter-block {
    .left {
      flex-grow: 1;

      .w-120 {
        flex-shrink: 0;
        // width: 88px;
      }
    }
    .right {
      margin-inline-start: inherit;
      justify-content: space-between;
    }

    @media screen and (min-width: 768px) {
      .right {
        justify-content: flex-end;
      }
    }
    @media screen and (max-width: 767px) {
      .left {
        .w-120 {
          width: 85px;
          padding-inline: 8px 4px;

          .ant-input-prefix {
            margin-inline-end: 4px;
          }
        }
      }
    }
  }
}

.rs-pagination {
  font-size: inherit;
  font-family: inherit;
  background: var(--grey-800);
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-self: center;
  gap: 2px;

  &.ant-table-pagination {
    margin-block: 0;
    border-end-start-radius: 8px;
    border-end-end-radius: 8px;
  }
  .ant-pagination-item {
    background: transparent;

    a {
      color: var(--text-idle);
      padding-inline: 4px;
    }
    &-active {
      color: var(--text-light);
      border-color: var(--text-idle);
      background-color: transparent;
    }
  }
  .ant-pagination-item:not(.ant-pagination-item-active):hover,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:active .ant-pagination-item-link,
  .ant-pagination-next:active .ant-pagination-item-link {
    color: var(--text-light);
    background: transparent;
  }
  .ant-pagination-options,
  .ant-pagination-item {
    margin-inline: 0;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
    font-size: inherit;
    font-weight: 500;
  }
  .ant-pagination-item {
    height: 40px;
    width: auto;
    aspect-ratio: 1;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    color: var(--text-idle);

    button {
      font-size: 20px;
      color: var(--text-idle);
    }
  }
  .ant-pagination-prev {
    margin-inline-end: auto;
  }
  .ant-pagination-next {
    order: 1000;
    margin-inline-start: auto;
  }

  // PAGE SIZER
  .ant-pagination-options-size-changer {
    color: inherit;
    font-family: inherit;
    line-height: inherit;
    height: auto;

    .ant-select-selector {
      padding-block: 4px;
      color: var(--text-idle);
      background: transparent;
      border-color: var(--text-idle);
    }
    .ant-select-arrow {
      color: var(--text-idle);
    }
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--text-idle);
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    font-size: inherit;
    color: var(--text-idle);
  }
}

.report-staff-content {
  .table-filter-block {
    .left {
      flex-basis: 62.4%;
      display: grid;
      grid-template-columns: 37.94% 28.46% 29.38%;
    }
    .right {
      flex-basis: 36.27%;
      display: grid;
      grid-template-columns: 72.56% 23.81%;
    }
  }
}

@import '../../styles/root.scss';
.t-fz-16px {
  font-size: 16px;
  line-height: 21px;
}

.t-fz-12px {
  font-size: 12px;
  line-height: 18px;
}

.t-fz-14px {
  font-size: 14px;
  line-height: 20px;
}

.pb-16px {
  padding-bottom: 16px !important;
}

.cl-E5E5E5 {
  color: #E5E5E5 !important;
}

.cl-999999 {
  color: #999999 !important;
}

.t-mw-290 {
  flex: 0 0 auto;
  width: 100%;
  max-width: 290px;
}

.t-table-member {
  &.rs-table .ant-table-tbody>tr>th,
  &.rs-table .ant-table-tbody>tr>td,
  &.rs-table tfoot>tr>th,
  &.rs-table .ant-table-thead>tr>th,
  &.rs-table tfoot>tr>td {
    padding: 16px;
  }
  .rs-button {
    background: transparent;
    border-color: transparent;
  }
  &.ant-table-wrapper {
    .ant-table-thead th.ant-table-column-sort {
      background: #262626;
    }
    .ant-table-column-sorter {
      display: none;
    }
    .ant-table-column-sort.ant-table-column-has-sorters {
      .anticon {
        transform: rotate(180deg);
      }
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover {
      background: #262626;
    }
  }
  .ctrl-info-table {
    display: grid !important;
    grid-template-columns: 40px 1fr;
    gap: 12px;
    // align-items: center;

    .img-box {
      flex: 0 0 auto;
      max-width: 40px;
      width: 100%;
    }
    // .text {
    //   flex-grow: 1;
    //   display: flex;
    // }
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none !important;
  }

  &.rs-table .ant-table-thead>tr>th {
    &:nth-child(1) {
      width: 160px;
    }
    &:nth-child(2) {
      width: 126px;
    }
    &:nth-child(3) {
      width: 126px;
    }
    &:nth-child(4) {
      width: 126px;
    }
    &:nth-child(5) {
      width: 168px;
    }
    &:nth-child(6) {
      width: 126px;
    }
    &:nth-child(7) {
      width: 126px;
    }
    &:nth-child(8) {
      width: 126px;
    }
  }
}

.popup-history {
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #F9D361;
  }

  .ant-tabs {
    font-family: var(--font-inter);

    .ant-tabs-tab {
      color: #999;
      width: 100%;
      .title-tab {
        display: flex;
        align-items: center;
        gap: 6px;
        .icon-svg {
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-tabs-content {
      color: #999;
    }

    .ant-tabs-ink-bar {
      background: #F9D361;
    }

    .list-normal {
      display: flex;
      gap: 16px;
      flex-direction: column;
      .one-item {
        display: flex;
        gap: 4px;
        border-bottom: 1px solid #262626;
        padding-bottom: 8px;
        .left {
          flex: 0 0 auto;
          max-width: 24px;
          width: 100%;
        }
        .right {
          line-height: 24px;
          flex-grow: 1;
          .item-jcsb {
            display: flex;
            gap: 8px;
            justify-content: space-between;
            // align-items: center;
            .price {
              font-weight: var(--font-bold);
              color: #fff;
            }
            .label {
              white-space: nowrap;
            }
          }
          .img-box {
            margin-top: 12px;
            border-radius: 12px;
            overflow: hidden;
            aspect-ratio: 260/174;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .filter-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 16px;
  }
  &.ant-drawer .ant-drawer-body {
    padding: 0 16px;
  }
}

.t-picker-ctrl {
  .ant-picker-input {
    border-color: var(--grey-900);
    background-color: var(--grey-900);
    color: var(--text-idle);
    padding-left: 44px;
    padding-right: 16px;
    padding-block: 4px;
    height: 40px;
    position: relative;
    border-radius: 8px;

    &:hover {
      border-color: var(--grey-800);
      background-color: var(--grey-800);
    }

    input {
      &::placeholder {
        color: var(--text-idle) !important;
      }
    }

    .anticon {
      color: var(--text-idle);
    }

    .ant-picker-suffix,
    .ant-picker-clear {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      margin-inline-start: 0;
      font-size: 20px;
      color: var(--text-idle);
    }
  }

  &.ant-picker {
    padding-block: 0 !important;
    padding: 0 !important;
    border-color: transparent !important;
    background: transparent !important;
  }
}

.ant-table {
  div[contenteditable="true"] {
    // text-decoration: underline;
    border-bottom: 1px solid var(--text-light);
  }
}

.member-content {
  .table-filter-block {
    @media screen and (max-width: 1199px) {
      display: grid;
      grid-template-areas: "search search search" "role shift level" "cashoutnormal export sendsms";
      grid-auto-columns: 1fr;

      > * {
        min-width: inherit;
        width: auto !important;
      }
      .grid-area {
        &-search {
          grid-area: search;
        }
        &-role {
          grid-area: role;
        }
        &-shift {
          grid-area: shift;
        }
        &-level {
          grid-area: level;
        }
        &-cashoutnormal {
          grid-area: cashoutnormal;
          margin-inline-start: 0;
        }
        &-export {
          grid-area: export;
        }
        &-sendsms {
          grid-area: sendsms;
        }
      }
    }
    @media screen and (max-width: 639px) {
      grid-template-areas: "search search" "role shift" "level cashoutnormal" "export sendsms";
    }
  }
}

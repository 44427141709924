.choose-store-item {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  border-radius: 12px;
  border: 1px solid var(--grey-800);
  padding: 12px;
  @media screen and ( max-width: 1199px ) {
    gap: 24px;
  }
  @media screen and ( max-width: 767px ) {
    flex-direction: column;
    gap: 16px;
  }

  .avatar {
    flex: 0 0 80px;
    max-width: 80px;
  }
  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    &-block {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .value {
        padding-block: 8px;
      }
      &-wrapper {
        &--with-button {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 8px;

          .ant-btn {
            padding-inline: 20px;
            font-size: 16px;

            &.default-outlined {
              &:not(:hover) {
                color: var(--text-idle);
                background: transparent;
                border-color: var(--text-idle);
              }
            }
          }
        }
      }
    }
  }
  &.disabled {
    border-color: var(--text-alert-base);
  }
}

.rs-checkbox {
  font-family: inherit;
  font-size: inherit;
  color: inherit;

  &:not(.ant-checkbox-check) {
    .ant-checkbox-inner {
      background: #F9F5FF;
    }
  }
  &:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #F9F5FF;
  }
  .ant-checkbox {
    font-family: inherit;
    font-size: inherit;
    color: inherit;

    &-inner {
      height: 20px;
      width: 20px;
      border-radius: 6px;
      border: none;

      &::after {
        height: 10px;
        width: 7px;
        border-width: 2px;
        margin-block-start: -1px;
      }
    }
    &-checked {
      .ant-checkbox-inner {
        background-color: #F9F5FF;

        &::after {
          border-color: #0F0F0F;
        }
      }
    }
  }
  &-group {
    font-family: inherit;
    font-size: inherit;
    color: inherit;

    &.vertical {
      display: flex;
      flex-direction: column;
    }
  }
}

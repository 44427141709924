.random-input-item {
  display: flex;
  align-items: center;
  gap: 10px;

  .rs-input.ant-input {
    padding: 12px 16px;
    background: var(--grey-800);
  }
  .ant-btn {
    transition: all .3s;
    border-radius: 8px;
    border-color: var(--text-alert-base);
    color: var(--text-alert-base);
    height: 36px;
    width: auto;
    aspect-ratio: 1;

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--text-light);
      background: var(--text-alert-base);
      border-color: var(--text-alert-base);
    }
  }
}

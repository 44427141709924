.rs-badge {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  white-space: nowrap;

  &.ant-badge-status {
    .ant-badge-status {
      &-default {
        background: var(--text-light);

        ~ .ant-badge-status-text {
          color: var(--text-light);
        }
      }
      &-success {
        background: var(--text-success);

        ~ .ant-badge-status-text {
          color: var(--text-success);
        }
      }
      &-warning {
        background: var(--text-active);

        ~ .ant-badge-status-text {
          color: var(--text-active);
        }
      }
      &-error {
        background: var(--text-red-3);

        ~ .ant-badge-status-text {
          color: var(--text-red-3);
        }
      }
      &-processing {
        background: var(--blue-700);

        ~ .ant-badge-status-text {
          color: var(--blue-700);
        }
      }
    }
  }
  &.background {
    font-size: 12px;
    line-height: 18px;
    background: var(--grey-800);
    border-radius: 16px;
    padding: 2px 8px 2px 6px;
  }
}

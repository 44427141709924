.rs-range-picker {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  justify-content: center;
  border-color: var(--grey-900);
  background-color: var(--grey-900);
  padding-block: 9px;

  .ant-picker {
    &-input {
      > input {
        font-size: inherit;
        line-height: inherit;
        text-align: center;
        min-width: 69px;

        &::placeholder {
          color: var(--text-idle);
        }
      }
    }
    &-range-separator {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &-separator {
      color: var(--text-idle);
    }
    &-suffix {
      color: var(--text-idle);
      order: -1;
      margin-inline: 0 4px;
    }
    &-active-bar {
      background: var(--primary-7);
    }
    &-clear {
      color: var(--text-idle);

      &:hover {
        color: var(--text-light);
      }
    }
  }
  &:focus,
  &:focus-within,
  &:hover {
    border-color: var(--grey-900);
    background: var(--grey-900);

    .ant-picker-suffix {
      opacity: 1 !important;
    }
  }
  &-dropdown {
    font-size: inherit;
    font-family: inherit;

    .ant-picker {
      &-header {
        > button {
          font-size: inherit;
        }
      }
    }
  }
}

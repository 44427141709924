.setting {
  &-layout {
    .ant-tabs-top > .ant-tabs-nav {
      margin-block-end: 24px;
    }
    .table-filter-block {
      &--user-list {
        @media screen and (max-width: 767px) {
          display: grid;
          grid-template-areas: "search search search" "role export create";
          grid-auto-columns: 1fr;

          .grid-area {
            &-search {
              grid-area: search;
            }
            &-role {
              grid-area: role;
              max-width: 100%;
            }
            &-export {
              grid-area: export;
              margin-inline-start: 0;
            }
            &-create {
              grid-area: create;
            }
          }
        }
        @media screen and (max-width: 639px) {
          grid-template-areas: "search search" "role role" "export create";
        }
      }
    }
  }
  &-content {
    flex-grow: 1;

    &.fl-columns-991 {
      @media screen and ( max-width: 991px ) {
        flex-direction: column;
        gap: 24px;
        justify-content: start;
      }
      .mw-596 {
        @media screen and ( max-width: 991px ) {
          flex: 0 0 auto;
          max-width: 100%;
          width: 100%;
        }
      }
      .button-update {
        @media screen and ( max-width: 991px ) {
          flex: 0 0 auto;
          max-width: 145px;
          width: 100%;
        }
      }
    }

    .button-magic-pen {
      height: 36px;
      width: auto;
      aspect-ratio: 1;
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &.default-outlined {
        border-color: var(--text-idle);
      }
      &.danger-outlined {
        &:not(:hover) {
          background: transparent;
        }
      }
    }
    .jetson-nano {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__device-name {
        --input-bg: transparent;
        cursor: default !important;
        padding: 0 !important;

        &:hover {
          border-color: transparent !important
        }
        input {
          cursor: default !important;
        }
      }
      .ant-btn {
        display: none;
      }
      &::after {
        content: "Device";
        color: var(--text-idle);
        font-size: 12px;
        font-family: var(--font-bold);
      }
    }
    .ant-form.is-owner {
      .jetson-nano .ant-form-item {
        display: none;
      }
    }
    &--update {
      padding: 24px;
      border-radius: 12px;
      background: var(--grey-900);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 24px;
      @media screen and ( max-width: 600px ) {
        padding: 12px;
        gap: 12px;
      }

      .mw {
        &-596 {
          flex: 0 0 auto;
          max-width: 596px;
          width: 100%;
        }
        &-687 {
          flex: 0 0 auto;
          max-width: 687px;
          width: 100%;
        }
      }
      .ant-form {
        --input-bg: var(--grey-800);

        &.form-gap-12 {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .ant-form-item {
            margin-block-end: 0;
            width: 100%;
          }
        }
      }
      .button {
        &-update {
          flex: 0 0 145px;
          width: 145px;
          font-family: var(--font-medium);
          @media screen and ( max-width: 600px ) {
            flex: 0 0 auto;
          }
        }
        &-duplicate {
          &.primary-outlined {
            &:not(:hover) {
              background: transparent
            }
          }
        }
      }
    }

    .line-600 {
      width: 100%;
      margin-top: 24px;
      margin-bottom: 24px;
      height: 1px;
      background-color: #fff;
      @media screen and ( min-width: 601px ) {
        display: none;
      }
    }

    .mt-12-600 {
      @media screen and ( max-width: 600px ) {
        margin-top: 12px;
      }
    }

    &.setting-my-account {
      display: block;

      .form-password-passcode {
        .ant-form-item.w-full {
          width: 100%;
        }
        .ant-btn {
          height: 45px;
        }
        .ctrl-btn {
          .ant-form-item-label {
            @media screen and ( max-width: 600px ) {
              display: none;
            }
          }
        }

        .ant-flex {
          @media screen and ( max-width: 600px ) {
            flex-direction: column;
            gap: 0 !important;
          }
        }
        .ant-form-item {
          .ant-row {
            @media screen and ( max-width: 600px ) {
              gap: 0 !important;
            }
          }
        }
      }
    }
  }
}

.table-filter-block.rps-600-flex-column {
  @media screen and ( max-width: 600px ) {
    flex-direction: column;
  }
  .w-349, .w-186 {
    @media screen and ( max-width: 600px ) {
      width: 100%;
    }
  }
}

.setting-content {
  height: 100vh;
  overflow-y: auto;

  @media screen and ( max-width: 991px ) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    .button-update {
      flex: 0 0 auto;
      width: 100%;
      max-width: 145px;
    }
  }
}

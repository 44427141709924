@include vwConvert() using ($s) {
  .rs-message.ant-message-notice {
    font-size: vw(14, $s);
    line-height: vw(22, $s);

    .ant-message-notice-content {
      padding: vw(9, $s) vw(12, $s);
      border-radius: vw(8, $s);
      box-shadow: 0 vw(6, $s) vw(16, $s) 0 rgba(0, 0, 0, 0.08),0 vw(3, $s) vw(6, $s) vw(-4, $s) rgba(0, 0, 0, 0.12),0 vw(9, $s) vw(28, $s) vw(8, $s) rgba(0, 0, 0, 0.05);
    }
    .ant-message-custom-content > .anticon {
      font-size: vw(16, $s);
      margin-inline-end: vw(8, $s);
    }
  }
}

.rs-message.ant-message-notice {
  font-family: var(--font-regular);
  color: var(--neutral-gray-700);
}

@include vwConvert() using ($s) {
  .arc-card {
    gap: vw(18, $s);
    padding: vw(24, $s);
    border-radius: vw(8, $s);

    &::before {
      inset: vw(-1, $s);
      border-radius: vw(8, $s);
    }
    &__head {
      .title {
        font-size: vw(16, $s);
        line-height: vw(24, $s);
      }
      .control {
        gap: vw(12, $s);
      }
      .ant-btn {
        font-size: vw(14, $s);
        line-height: vw(22, $s);
      }
      .ant-select {
        min-width: vw(152, $s);
      }
    }
    .top-car {
      &-list {
        gap: vw(16, $s);
      }
      &-item {
        grid-template-columns: vw(240, $s) vw(220, $s) vw(63, $s);
        gap: vw(8, $s);
      }
    }
    .customer-grow-chart {
      canvas {
        height: vw(368, $s) !important;
      }
    }
    .chart-h350 {
      > div {
        position: relative;
        aspect-ratio: 539/350;
      }
      canvas {
        height: 100% !important;
        width: 100% !important;
        position: absolute;
        inset: 0;
      }
    }
  }
}

.arc-card {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #FFFFFF;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    background: #0000000F;
  }
  &.primary-gradient {
    background: linear-gradient(180deg, #E3F1FE 0%, #FFFFFF 100%);
    
    &::before {
      background: linear-gradient(117.12deg, #D0EEFF -7.66%, rgba(203, 232, 248, 0.44) 66.93%);
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-family: var(--font-medium);
    }
    .control {
      display: flex;
      align-items: center;
    }
    .ant-btn {
      font-family: var(--font-regular);
      border-color: var(--neutral-gray-100);
    }
    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      border-color: var(--neutral-gray-100);
    }
  }
  .top-car {
    &-list {
      display: flex;
      flex-direction: column;
    }
    &-item {
      display: grid;

      .brand,
      .quantity {
        color: var(--neutral-gray-500);
      }
      .quantity {
        text-align: end;
      }
    }
  }
  // .customer-grow-chart {
  //   canvas {
  //     aspect-ratio: 539 / 368;
  //     height: 100% !important;
  //     width: 100% !important;
  //   }
  // }
  // .chart-h350 {
  //   canvas {
  //     aspect-ratio: 539 / 350;
  //     height: 100% !important;
  //     width: 100% !important;
  //   }
  // }
}
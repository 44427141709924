@import '../../styles/root.scss';
.t-w-100 {
  width: 100% !important;
}
.t-popup-px-16 {
  .ant-drawer-body {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
.owner-detail {
  .info-owner {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .name-avatar {
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .avatar {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        font-size: 15px;
        font-weight: 500;
        color: #e5e5e5;
      }
    }

    .status-block {
      height: 44px;
      background-color: #0F0F0F;
      box-shadow: 0px 1px 2px 0px #1018280D;
      border-radius: 8px;
      padding: 0 0 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
        border-color: transparent !important;
        outline: none;
      }
      .type-2, .type-1 {
        position: relative;
        padding-left: 12px;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
      .type-1 {
        color: #12B76A;
        &::before {
          background-color: #12B76A;
        }
      }

      .type-2 {
        color: #F5222D;
        &::before {
          background-color: #F5222D;
        }
      }
    }

    .one-item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .title {
        font-size: 12px;
        font-weight: 700;
        color: #999999;
      }
      .content {
        font-size: 15px;
        color: #E5E5E5;
      }
    }
  }

  .list-item {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    .one-item {
      padding: 12px;
      border-radius: 12px;
      border: 1px solid #262626;
      display: flex;
      gap: 32px;
      .img-box {
        flex: 0 0 auto;
        max-width: 80px;
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        overflow: hidden;
        height: 80px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .content-block {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      .one-item-sub {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .title {
          font-size: 12px;
          font-weight: 700;
          color: #999999;
        }

        .content {
          font-size: 15px;
          color: #E5E5E5;
        }
      }
    }
  }
}
.account-manager-seller-content,
.account-manager-owner-content {
  .table-filter-block {
    justify-content: flex-end;

    &__search {
      width: 349px;
      margin-inline-end: auto;
      @media screen and ( max-width: 767px ) {
        width: 100%;
      }
    }
  }
}

@include vwConvert() using ($s) {
  .rs-search {
    .ant-input {
      height: vw(32, $s);
      padding: vw(4, $s) vw(12, $s);
      border-radius: vw(8, $s);
      font-size: vw(14, $s);
      line-height: vw(22, $s);

      &-outlined {
        border-width: vw(1, $s);
      }
      &-search-button {
        width: vw(32, $s);
        height: vw(32, $s);
        border-radius: 0 vw(8, $s) vw(8, $s) 0 !important;

        .anticon {
          font-size: vw(14, $s);
        }
      }
    }
  }
}

.rs-search {
  .ant-input {
    font-family: var(--font-regular);
    color: var(--neutral-gray-700);
    background-color: #FCFCFD;

    // &-outlined {
    //   border-color: var(--neutral-gray-100);
    // }
    &-search-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: #FCFCFD;
    }
  }
}

.game-player {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  box-shadow: inset 0 -1px 1px var(--grey-800);
  transition: all .3s;

  .avatar {
    height: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    flex-shrink: 0;
  }
  .content {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ant-btn {
    margin-inline-start: auto;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    border-radius: 8px;
    border-color: var(--text-alert-base);
    color: var(--text-alert-base);
    height: 36px;
    width: auto;
    aspect-ratio: 1;

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--text-light);
      background: var(--text-alert-base);
      border-color: var(--text-alert-base);
    }
  }
  &:hover {
    background: rgba(38, 38, 38, .15);

    &:not(.disabled) {
      .ant-btn {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
}

.sales-report-item {
  background: var(--grey-900);
  padding: 24px;
  border-radius: 8px;
  min-height: 104px;
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
  display: flex;
  flex-direction: column;
  gap: 18px;

  .title {
    color: var(--text-idle);
    font-family: var(--font-bold);
  }
  .number {
    color: var(--text-light);
    font-size: 24px;
    font-family: var(--font-bold);
  }
}

@import "../../styles/root.scss";
.mw-320px {
  max-width: 320px !important;
  flex: 0 0 auto;
  width: 100%;
}

.machine-content {
  height: 100%;

  .content-block ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
    gap: 24px;
    margin-bottom: 24px;
    padding-inline-start: 0 !important;

    .one-item {
      display: flex;
      gap: 24px;
      flex-direction: column;
      background-color: #0F0F0F;
      padding: 12px;
      border-radius: 12px;
      overflow: hidden;
      position: relative;

      .bg-img {
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;
      }

      .list-ctrl {
        display: flex;
        gap: 8px;

        .rs-button {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          background-color: #262626;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 4px 12px 0px #00000040;
        }
      }

      .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        .id-order-block {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-block-start: auto;
        }

        .tag {
          padding: 5px 12px;
          line-height: 1;
          color: #999;
          font-weight: 600;
          border-radius: 8px;
          background-color: #262626;
        }
      }
    }

    // @media screen and (min-width: 768px) {
    //   grid-template-columns: repeat(3, 1fr);
    // }
    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  // .list-machine {
  //   display: grid;
  //   grid-template-columns: repeat(4, 1fr);
  //   gap: 24px;
  //   margin-bottom: 24px;

  // }

  .t-Pagination {
    margin-block-start: auto;
  }

  .table-filter-block {
    // justify-content: space-between;

    .title-filter {
      display: none;
    }
    .left {
      flex-grow: 1;
    }
    .right {
      margin-inline-start: inherit;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: 768px) {
    .table-filter-block {
      .title-filter {
        display: block;
        white-space: nowrap;
      }
      .right {
        flex-direction: row;
        justify-content: flex-end;
      }
      &__search {
        max-width: 320px;
        margin-inline-start: auto;
      }
    }
  }
}

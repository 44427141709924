.game-results {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
  border-radius: 8px;
  overflow: hidden;

  &__header,
  &__footer {
    align-items: center;
    gap: 12px;
    padding: 12px 24px;
    background: var(--grey-800);
  }
  &__header {
    display: flex;
    color: var(--text-light);
  }
  &__content {
    overflow-y: auto;
    flex-grow: 1;
    background: var(--grey-900);

    &.random-game {
      padding: 24px;

      &.v2 {
        gap: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  &__footer {
    .ant-btn {
      .ant-btn-icon + span {
        flex-grow: 1;
      }
    }
  }
  &-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 98;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    display: none;

    &.show {
      display: block;
    }
  }

  @media screen and (max-width: 1023px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 320px;
    transform: translateX(-100%);
    transition: transform .25s;
    z-index: 99;

    &.show {
      transform: translateX(0);
    }
  }
}

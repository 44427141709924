@import '../../styles/root.scss';
.h-44 {
  height: 44px !important;
}

.h-48 {
  height: 48px !important;
}

.b-r {
  border-radius: 8px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mw-464 {
  max-width: 464px !important;
  width: 100%;
}

.t-fz-24 {
  font-size: 24px !important;
  line-height: 32px !important;
}

.t-fz-16 {
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400;
}

.login-page {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  min-height: 1080px;
  @media screen and ( max-width: 1199px ) {
    min-height: 100vh;
  }
  .bg-img {
    top: 215px;
    left: 1029px;
    z-index: 3;
    width: 650px;
    height: 650px;
  }

  .img-logo {
    position: absolute;
    left: 240px;
    top: 188px;
    z-index: 2;
    @media screen and ( max-width: 1199px ) {
      left: 16px;
      top: 16px;
    }
  }

  .bg-img-checkin-progress-ellipse {
    z-index: 1;
    background: transparent;
  }

  .img-logo {
    width: 182px;
    height: 47px;
  }

  .content-block {
    margin-left: 240px;
    position: relative;
    z-index: 2;
    @media screen and ( max-width: 1199px ) {
      margin-left: 16px;
      margin-right: 16px;
      padding-top: 76px;
      padding-bottom: 24px;
    }

    .title {
      font-size: 48px;
      // line-height: 1;
      // font-weight: 700;
      line-height: 65px;
      font-family: var(--font-bold);
      margin-bottom: 24px;
      @media screen and ( max-width: 1199px ) {
        font-size: 28px;
        line-height: 1.4;
      }
      @media screen and ( max-width: 1199px ) {
        font-size: 18px;
      }
    }
    .sum {
      color: #F9D361;
      font-size: 32px;
      line-height: 1;
      margin-bottom: 48px;
    }
    .btn-block {
      display: flex;
      align-items: center;
      gap: 24px;
      @media screen and ( max-width: 450px ) {
        flex-direction: column;
        gap: 16px;
      }
      .t-btn-primary {
        max-width: 166px;
        display: flex;
        gap: 8px;
        // font-weight: 700;
        font-family: var(--font-bold);
        @media screen and ( max-width: 450px ) {
          max-width: 100%;
        }
        &.btn-new-pw {
          max-width: none;
          width: auto;
          display: inline-flex;
        }
        .icon-svg {
          display: flex;
          align-items: center;
        }
      }
      .text-reset {
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        .btn-reset {
          margin-left: 4px;
          color: #F9D361;
          font-weight: 400;
          cursor: pointer;
          font-size: 16px;
          line-height: 22px;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .btn {
      width: 100%;
      height: 80px;
      border-radius: 8px;
      border: 3px solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02px;
      font-weight: 400;
      color: #667085;
      background-color: #ffffff;
      display: flex;
      gap: 8px;
      align-items: center;

      &.t-btn-primary {
        background: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%);
        border: none;
        color: #080808;
      }

      &.btn-border-text-primary {
        color: #F9D361;
        border-color: #F9D361;
        border-width: 1px;
      }

      .icon-svg {
        display: flex;
        align-items: center;
      }
    }

    .ant-otp {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
      .ant-input {
        margin-bottom: 0;

        &.ant-input-outlined {
          border-top: none;
          border-left: none;
          border-right: none;
          // border-bottom: 1px solid #999999;
          border-radius: 0;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.02px;
          padding: 0;
          padding-bottom: 18px;
          color: #fff;
          background-color: transparent;
          text-align: left;

          &::placeholder {
            color: #999;
          }

          &:focus,
          &:hover {
            border-color: #F9D361;
            box-shadow: none;
          }
        }

        .input-block input:not(:placeholder-shown) {
          border-color: #F9D361;
        }

        .ant-form-item-explain-connected {
          display: none;
        }
      }
    }

    .list-error {
      .one-error {
        &.active {
          color: #fff;
          .icon-svg {
            color: #55c790;
          }
        }
      }
    }

    .form-new-pw {
      .ant-form-item-explain-error {
        display: none;
      }
      .btn-new-pw:disabled {
        background: #999999 !important;
        color: #E5E5E5;
      }
    }
  }
  .bg-img {
    position: absolute;
    top: 52px;
    left: 727px;
    z-index: 1;
    width: 1301px;
    height: 975px;
    @media screen and ( max-width: 1199px ) {
      left: 0;
    }
  }

  .fz-80 {
    font-size: 80px;
    line-height: 108px;
    letter-spacing: -0.02px;
    font-weight: 700;
  }

  .fz-24 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02px;
  }

  .ctrl-group-gender {
    margin-bottom: 64px;
    .label {
      font-size: 16px;
      line-height: 22px;
      color: #999;
      margin-bottom: 10px;
    }
  }

  .input-icon-block {
    .ant-input-affix-wrapper {
      height: 100%;
    }

    input {
      color: #667085;
      font-size: 16px;
      line-height: 22px;
    }

    .ant-input-prefix {
      margin-inline-end: 8px;
    }
  }

  .btn-back {
    width: 77px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #999;
    color: #999;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 64px;
  }

  .ctrl-pd-t-1199 {
    @media screen and ( max-width: 1199px ) {
      padding-top: 16px;
    }
  }

  .btn-flex-2 {
    gap: 12px !important;

    .btn-send {
      max-width: 185px !important;
      width: 100%;
      @media screen and ( max-width: 450px ) {
        max-width: 100% !important;
      }
    }
    .btn-send-phone-username {
      flex-grow: 1;
      max-width: none !important;
      gap: 0;
    }
  }

  .list-error {
    color: #999999;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .one-error {
      display: flex;
      gap: 8px;
      .icon-svg {
        display: flex;
        align-items: center;
      }
    }
  }
}

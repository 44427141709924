@import '../../styles/root.scss';
.mw-690px {
  max-width: 690px;
  width: 100%;
}
.mb-12 {
  margin-bottom: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.tab-setting {
  .ant-tabs-nav-list {
    display: flex;
    .title-tab-setting {
      min-width: 120px;
      padding: 0 18px;
      height: 35px;
      color: #999999;
      text-align: center;
    }
  }
  &.ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    .title-tab-setting {
      color: #F9D361;
    }
  }

  &.ant-tabs .ant-tabs-tab {
    padding: 0;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  &.ant-tabs-top>.ant-tabs-nav::before,
  &.ant-tabs-bottom>.ant-tabs-nav::before,
  &.ant-tabs-top>div>.ant-tabs-nav::before,
  &.ant-tabs-bottom>div>.ant-tabs-nav::before {
    border-bottom: 1px solid #262626;
  }

  .ant-tabs-content-holder {
    padding: 24px;
    border-radius: 12px;
    background-color: #0F0F0F;
  }

  .shift-content {
    .ant-tabs-content-holder {
      padding: 0;
    }
    .ant-tabs-nav-list {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      gap: 8px;

      .ant-tabs-tab {
        padding: 12px 16px;
        background-color: #262626;
        border-radius: 8px;
        color: #E5E5E5;
      }
    }

    .list-check-days .one-check {
      background-color: #262626;
    }

    .one-input-time{
      &.v2{
        .left{
          padding-right: 8px;
        }
        .ant-form-item-explain{
          display: none;
        }
        .rs-date-picker{
          height: 45px;
          background: #262626;
        }
        .w-100{
          width: 100%;
        }
        .rs-input{
          height: 45px !important;
          background: #262626;
        }
      }
      &.one-2column  {
        .ctrl-left {
          display: grid;
          gap: 8px;
          flex-grow: 1;
          grid-template-columns: repeat(2, 1fr);
          &.full{
            grid-template-columns: 1fr;
          }
          .left {
            max-width: 100%;
          }
          &.time-ranger {
            display: flex;
            flex-direction: row;
            gap: 8px;
            position: relative;
            padding-inline: 16px;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: 45px;
              box-shadow: 0px 1px 2px 0px #1018280D;
              background: var(--grey-800);
              border-radius: 8px;
            }
            > * {
              position: relative;
              z-index: 1;
            }
            > svg {
              height: 45px;
              flex: 0 0 20px;
              max-width: 20px;
            }
            .rs-date-picker {
              width: 100%;
              height: 45px;
              text-align: center;
              background: none !important;
              border: none !important;

              &:focus-within {
                box-shadow: none;
              }
              input {
                text-align: center;
              }
            }
            .ant-form-item {
              flex-grow: 1;
            }
            .horizontal-divider {
              height: 45px;
              flex: 0 0 20px;
              max-width: 20px;
              display: inline-flex;
              align-items: center;

              &::before {
                content: "";
                display: block;
                width: 100%;
                border-top: 1px solid var(--text-idle);
              }
            }
          }
        }
      }
      &.one-2column-70-30 {
        .ctrl-left {
          display: grid;
          gap: 8px;
          flex-grow: 1;
          grid-template-columns: 1fr 150px !important;
          @media screen and ( max-width: 500px) {
            grid-template-columns: 1fr 50px !important;
          }
          .left {
            max-width: 100%;
            .rs-date-picker {
              @media screen and ( max-width: 500px) {
                padding: 10px 4px;
              }
            }
          }
          .center {
            height: 100%;
            input {
              height: 100%;
              border-radius: 8px;
              background-color: #262626;
            }
          }
        }
      }
      &.one-1column {
        .ctrl-left {
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;

          .left {
            max-width: 100%;
          }
        }
      }

      input {
        color: #E5E5E5 !important;
      }

      .left {
        background-color: #262626;
        .ant-input-affix-wrapper {
          // height: 100%;
          height: 45px;
          border-radius: 8px;
          background-color: #262626;
        }
      }

      .center {
        .ant-input-affix-wrapper {
          height: 100%;
          border-radius: 8px;
          background-color: #262626;
        }
      }
    }

    .t-range-picker-ctrl {
      border-color: #262626;
      background-color: #262626;
    }
  }
  .ant-form {
    max-width: 100%;
  }
  &.ctrl-tab-user {
    .ant-tabs-content-holder {
      padding: 0;
      background-color: transparent;
    }
  }
}

.tab-rps {
  .ant-tabs-nav {
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevents tab text from wrapping */
  }

  .ant-tabs-nav-wrap {
    overflow: hidden; /* Hides any excess outside the container */
  }

  .ant-tabs-nav-list {
    display: inline-flex;
  }

  .ant-tabs-tab {
    flex-shrink: 0; /* Prevent tabs from shrinking */
  }

  /* Optional styling for better UX */
  .ant-tabs-nav {
    scrollbar-width: thin; /* Firefox */
  }

  .ant-tabs-nav::-webkit-scrollbar {
    height: 6px; /* Change the height of the scrollbar */
  }

  .ant-tabs-nav::-webkit-scrollbar-thumb {
    background-color: #888; /* Style the scrollbar */
    border-radius: 3px;
  }

  .ant-tabs-nav::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .rs-tabs .ant-tabs-tab {
    height: 40px;
    padding: 0 18px;
  }
}

.tabs-ctrl-btnsm {
  position: relative;
  .btn-update {
    background: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%);
    height: 40px;
    width: 100%;
    max-width: 145px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 78px;
    right: 24px;
    z-index: 1;
    font-weight: 500;
    &:hover {
      color: #0F0F0F !important;
      background: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%) !important;
      opacity: 0.8;
    }
  }
}

.tab-match-rules {
  .ant-collapse>.ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    color: #999;
    padding: 0;
  }
  .match-tag.ant-select-outlined {
    .ant-select-selector {
      background-color: #262626;
      height: 45px;
      padding-inline-start: 42px;
    }
    .ant-select-arrow {
      inset-inline-end: auto;
      inset-inline-start: 16px;
    }
    .ant-select-selection-placeholder {
      inset-inline-start: 42px;
    }
  }

  .ant-collapse {
    border: none;
    .ant-collapse-content {
      background-color: transparent;
      border: none;
    }
    .ant-collapse-content-box{
      padding: 16px 0 0 0;
      background-color: transparent;
      color: #999999;
    }
    .ant-collapse-expand-icon {
      display: none !important;
    }
    .ant-collapse-item.ant-collapse-item-active  {
      .title-collapse {
        &::before {
          border: 3px solid #F9D361;
        }
      }
      .content {
        font-size: 15px;
      }
    }
    .title-collapse{
      background-color: #262626;
      border-radius: 8px;
      padding: 0 16px;
      height: 45px;
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border: 3px solid #fff;
        background-color: #fff;
        border-radius: 50%;
      }
      .icon-svg {
        display: flex;
        align-items: center;
        color: #999;
      }
      .text{
        color: #E5E5E5;
      }
    }
    .text-or {
      margin-bottom: 8px;
      margin-top: 16px;
      color: #E5E5E5;
    }
  }
}

.ctrl-input-number {
  margin-left: 10px;
  margin-right: 10px;

  input {
    font-size: 18px;
    color: #E5E5E5;
    padding: 0 0 0 10px;
    height: 32px;
    background-color: #262626;
    outline: none;
    border-color: transparent;
    width: 64px;
    border-radius: 8px;
    text-align: center;
  }
}

.line-and {
  padding: 12px 0;
  text-align: center;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #FFFFFF;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .text {
    font-size: 16px;
    color: #999;
    min-width: 64px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #0F0F0F;
    position: relative;
    z-index: 1;
  }
}

.match-in-speacial-day-block {
  &.active {
    .title-match-in-speacial-day {
      .icon-acitve {
        display: block;
      }
    }
  }
  .title-match-in-speacial-day {
    background-color: #262626;
    border-radius: 8px;
    padding: 0 16px;
    height: 45px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;

    // &::before {
    //   position: absolute;
    //   content: "";
    //   right: 16px;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   width: 16px;
    //   height: 16px;
    //   border: 3px solid #fff;
    //   background-color: #fff;
    //   border-radius: 4px;
    // }

    .icon-svg {
      display: flex;
      align-items: center;
      color: #999;
    }

    .text {
      color: #E5E5E5;
    }

    .icon-acitve {
      position: absolute;
      right: 19px;
      top: 50%;
      transform: translateY(-50%);
      display: none;
    }
  }

  .content-match-in-speacial-day {
    color: #fff;
    display: none;
    margin-top: 16px;
    &.active {
      display: block;
    }
    .ant-select {
      .ant-select-selector {
        background-color: #262626;
        height: 100%;
      }
    }
    .rs-select.ant-select-outlined .ant-select-selector {
      border: none;
      background-color: transparent;
      color: var(--text-idle);
      padding-inline: 16px;
      padding-block: 0;
      height: 40px;
      &:hover {
        border: none;
      }
    }
  }
}

.mw-500-column {
  @media screen and ( max-width: 500px ) {
    .ant-tabs-nav-list {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}
.smatch{
  display: flex;
  align-items: center;
  gap: 16px;
  .text{
    display: flex;
    line-height: 1em;
  }
  .ant-switch.ant-switch-checked{
    background: #28c76f;
  }
  .ant-switch{
    background: var(--grey-700);
  }
  .ant-switch:hover:not(.ant-switch-disabled){
    background: var(--grey-700);
  }
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
    background: #28c76f;
  }
}

.app-menu-vertical {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 16px;
  font-weight: 700;

  .ant-menu {
    font-size: inherit;
    font-family: inherit;
  }
  .ant-menu-light .ant-menu-item,
  .ant-menu-light>.ant-menu .ant-menu-item,
  .ant-menu-light .ant-menu-item-group-title,
  .ant-menu-light>.ant-menu .ant-menu-item-group-title,
  .ant-menu-light .ant-menu-submenu-title,
  .ant-menu-light>.ant-menu .ant-menu-submenu-title {
    color: var(--text-idle);
  }
  .ant-menu-light,
  .ant-menu-light>.ant-menu {
    background: var(--grey-900);
  }
  .ant-menu .ant-menu-item-group-title {
    font-size: inherit;
    padding: 0;
  }
  .ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
  .ant-menu-light>.ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
  .ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) >.ant-menu-submenu-title:hover,
  .ant-menu-light>.ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) >.ant-menu-submenu-title:hover,
  .ant-menu-light .ant-menu-item-selected,
  .ant-menu-light>.ant-menu .ant-menu-item-selected,
  .ant-menu-submenu-open > .ant-menu-submenu-title {
    color: var(--text-light);
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
  .ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
  .ant-menu-light .ant-menu-item-selected,
  .ant-menu-light>.ant-menu .ant-menu-item-selected {
    background: transparent;
  }
  .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title,
  .ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title,
  .ant-menu .ant-menu-item-group .ant-menu-item-group-list > .ant-menu-item-selected {
    color: var(--text-dark);
    background: linear-gradient(97.83deg, #FFE279 -2.91%, #F2C142 101.74%);
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
  .ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    color: var(--text-light);
    background: transparent;
  }
  .ant-menu-light :not(.ant-menu-submenu-selected):hover > .ant-menu-submenu-title:not(:active),
  .ant-menu-light > .ant-menu :not(.ant-menu-submenu-selected):hover > .ant-menu-submenu-title:not(:active) {
    color: var(--text-light);
  }
  .ant-menu-item-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-inline: 16px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item,
  .ant-menu .ant-menu-item-group .ant-menu-item-group-list .ant-menu-submenu-title {
    margin: 0;
    padding: 12px 16px !important;
    height: auto;
    line-height: 22px;
    width: 100%;
  }
  .ant-menu .ant-menu-item .ant-menu-item-icon +span,
  .ant-menu .ant-menu-submenu-title .ant-menu-item-icon +span,
  .ant-menu .ant-menu-item .anticon +span,
  .ant-menu .ant-menu-submenu-title .anticon +span {
    margin-inline-start: 12px;
  }
  .ant-menu-inline .ant-menu-sub.ant-menu-inline {
    padding-inline-start: 40px;
  }
}

.menu-collapsed {
  .menu-trigger {
    text-align: center;
  }
  .ant-menu-inline-collapsed,
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }
    // .ant-menu-sub,
    .ant-menu-title-content,
    .ant-menu-submenu-arrow,
    >.ant-menu-submenu>.ant-menu-submenu-title .anticon +span,
    >.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon +span {
      display: none;
    }
  }
}

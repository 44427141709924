.ant-notification {
  bottom: 0 !important;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
  margin-block-end: 24px;
  margin-inline-end: 24px;

  .ant-notification-notice-wrapper {
    margin: 0;
    background: transparent;
    border-radius: 6px;

    .ant-notification-notice {
      font-family: var(--font-regular);
      padding: 9px 16px;
      border-radius: 6px;
      font-size: 14px;
      line-height: 22px;
      width: 384px;
      max-width: calc(100vw - 48px);

      &-message {
        color: inherit;
      }
      &-icon {
        position: static;
        font-size: 18px;

        &-success {
          &.anticon {
            color: var(--success-600);
          }
        }
        &-error {
          &.anticon {
            color: var(--text-alert);
          }
        }
        &-warning {
          &.anticon {
            color: var(--sunset-orange-6);
          }
        }
      }
      &-success {
        background: var(--success-25);
        color: var(--text-success);
        border: thin solid var(--success-600);
      }
      &-error {
        background: var(--dust-red-1);
        color: var(--text-alert);
        border: thin solid var(--text-alert);
      }
      &-warning {
        background: var(--sunset-orange-1);
        color: var(--sunset-orange-6);
        border: thin solid var(--sunset-orange-6);
      }
      &.single-message {
        display: flex;
        align-items: center;

        .ant-notification-notice {
          &-message {
            margin: 0;
            padding: 0;
            font-size: 16px;
          }
          &-description {
            display: none;
          }
          &-with-icon {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
        .ant-notification-notice-close {
          position: static !important;
          width: auto;
          height: auto;
          color: var(--text-idle);
          margin-inline-start: auto;

          &:active,
          &:hover {
            color: var(--text-dark);
            background: transparent;
          }
        }
      }
    }
  }
}

.das-total-match-modal {
  width: 700px !important;
  &.rs-modal{
    .ant-modal-body{
      padding: 16px;
    }
  }

  .title-mix{
    font-size: 24px;
    text-align: center;
    margin-bottom: 16px;
    font-weight: bold;
  }

  .table-filter-block {
    padding-block-start: 24px;

    .left {
      > * {
        // flex: 0 0 223px;
        width: 223px;
      }
    }
    .right {
      .search-group {
        // flex: 0 0 349px;
        width: 349px;
      }
      .ant-btn {
        // flex-basis: 186px;
        width: 186px;
      }
    }
  }
}

.store-list-content {
  .table-filter-block {
    @media screen and (max-width: 1023px) {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-areas: "search search type type status status" "export export mystore mystore ownerstore ownerstore";

      .grid-area {
        &-search {
          grid-area: search;
          max-width: 100%;
        }
        &-type {
          grid-area: type;
          max-width: 100%;
        }
        &-status {
          grid-area: status;
          max-width: 100%;
        }
        &-export {
          grid-area: export;
          margin: 0;
        }
        &-my-store {
          grid-area: mystore;
        }
        &-owner-store {
          grid-area: ownerstore;
        }
      }
    }
    @media screen and (max-width: 639px) {
      grid-template-areas: "search type" "status export" "mystore ownerstore";
    }
  }
}

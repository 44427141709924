.rs-form {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;

  .ant-form {
    &-item {
      font-family: var(--font-regular);
      color: var(--neutral-gray-700);

      .ant-form-item-explain,
      .ant-form-item-extra {
        font-family: var(--font-regular);
      }
      .ant-form-item-explain-error {
        color: var(--text-alert);
      }
    }
  }
  .ant-col,
  .ant-row {
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  &.ant-form-vertical {
    .ant-form-item-row {
      gap: 8px;
    }
    .ant-form-item-label {
      padding-block-end: 0 !important;

      > label {
        font-size: 12px;
        color: var(--text-idle)
      }
    }
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input-number-input {
      padding: 10px 16px;
      font-size: 15px;
    }
    .ant-input-affix-wrapper > .ant-input {
      padding: 0;
      color: inherit;
    }
    .ant-picker {
      font-size: 15px;
      padding-block: 12px;
    }
    .ant-select .ant-select-selector {
      font-size: 15px;
      padding-block: 6.5px;
    }
  }
  &.form-setting-type {
    .ant-flex {
      .label {
        flex: 0 0 200px;
        max-width: 200px;
        height: 43px;
        align-self: center;
        @media screen and ( max-width: 420px ) {
          flex: 0 0 auto;
          max-width: 140px;
          width: 100%;
        }
      }
      .ant-form-item {
        flex: 0 0 150px;
        max-width: 150px;
      }
    }
    .ant-input-number {
      width: 100%;

      .ant-input-number-input {
        text-align: center;
      }
    }
  }
}

.rs-date-picker {
  font-family: inherit;
  color: var(--text-light);
  background: var(--grey-900);
  font-size: inherit;
  padding: 10px 16px;
  border: none;
  &:hover{
    .ant-picker-clear{
      color: var(--text-light);
    }
    .ant-picker-suffix:not(:last-child){
      opacity: 1;
    }
  }
  &.v2{
    background-color: #262626;
    &:hover{
      .ant-picker-clear{
        color: var(--text-light);
      }
      .ant-picker-suffix:not(:last-child){
        opacity: 1;
      }
    }
    &:focus,
    &:active,
    &:focus-within{
      outline: none;
      box-shadow: none;
    }
    &.ant-picker-outlined:hover,
    &.ant-picker-outlined:active,
    &.ant-picker-outlined:focus-within{
      background-color: #262626;
    }
  }

  .ant-picker {
    &-input {
      flex-direction: row-reverse;

      > input {
        font-size: inherit;
        line-height: inherit;

        &::placeholder {
          color: var(--text-idle);
        }
      }
    }
    &-separator {
      color: var(--text-idle);
    }
    &-suffix {
      color: var(--text-idle);
      margin-inline: 0 8px;
    }
    &-active-bar {
      background: var(--primary-7);
    }
  }
  &-dropdown {
    font-size: inherit;
    font-family: inherit;

    .ant-picker {
      &-header {
        > button {
          font-size: inherit;
        }
      }
    }
  }
  &.ant-picker-outlined {
    &:hover,
    &:focus,
    &:focus-within {
      background-color: var(--grey-900);
      border-color: inherit;
    }
  }

  // TIME PICKER
  .ant-picker-time-panel-container {
    .ant-picker-time-panel-column >li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
      color: var(--neutral-gray-700);
    }
  }
}

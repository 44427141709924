.t-mb-8 {
  margin-bottom: 8px;
}
.mw-596px {
  max-width: 596px;
  width: 100%;
}
.t-fz-12 {
  font-size: 12px !important;
}
.t-fz-15 {
  font-size: 15px !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.cl-primary {
  color: #F9D361;
}

.shift-content {
  .ant-tabs-nav-list {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    .ant-tabs-tab {
      padding: 12px 16px;
      background-color: #0F0F0F;
      border-radius: 8px;
      color: #E5E5E5;
      .ant-tabs-tab-btn, .title-tab {
        width: 100%;
      }
      .title-tab {
        display: flex;
        gap: 8px;
        align-items: center;
        .icon-svg {
          display: flex;
          align-items: center;
        }
        .text {
          position: relative;
          padding-right: 24px;
          width: 100%;
          &::before {
            position: absolute;
            content: "";
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            border: 3px solid #fff;
            background-color: #fff;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .ant-tabs-top>.ant-tabs-nav::before,
  .ant-tabs-bottom>.ant-tabs-nav::before,
  .ant-tabs-top>div>.ant-tabs-nav::before,
  .ant-tabs-bottom>div>.ant-tabs-nav::before {
    border: none !important;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #E5E5E5;
    text-shadow: 0 0 0.25px currentcolor;
    .text {
      &::before {
        border: 3px solid #F9D361;
      }
    }
  }
  .ant-tabs-top>.ant-tabs-nav {
    margin-bottom: 24px;
  }
  .ant-tabs {
    color: #E5E5E5;
  }
  .always-open {
    .title-top {
      margin-bottom: 12px;
      color: #E5E5E5;
    }
  }
  .btn-add-new {
    width: 100%;
    background-color: transparent;
    border: 1px solid #F9D361;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F9D361;
    gap: 4px;
    &:hover {
      color: #F9D361 !important;
      border-color: #F9D361 !important;
      background: transparent !important;
    }
    .icon-svg {
      display: flex;
      align-items: center;
    }
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
}

.one-input-time {
  display: flex;
  gap: 8px;
  color: #999999;
  input {
    &::placeholder {
      opacity: 0.8  !important;
    }
    color: #fff !important;
  }
  .left {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 45px;
    flex: 0 0 auto;
    max-width: 150px;
    width: 100%;
    background-color: #0F0F0F;
    border-radius: 8px;
    @media screen and ( max-width: 500px ) {
      max-width: 100px;
    }
    .icon-svg {
      display: flex;
      align-items: center;
    }
  }
  .center {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px;
    .note {
      display: flex;
      gap: 4px;
      width: 100%;
      justify-content: flex-end;
      .cl-primary {
        border-bottom: 1px solid #F9D361;
      }
    }
    &.time-ranger {
      display: flex;
      flex-direction: row;
      gap: 8px;
      position: relative;
      padding-inline: 16px;
      @media screen and ( max-width: 500px ) {
        padding-inline: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 45px;
        box-shadow: 0px 1px 2px 0px #1018280D;
        background: var(--grey-800);
        border-radius: 8px;
      }
      > * {
        position: relative;
        z-index: 1;
      }
      > svg {
        height: 45px;
        flex: 0 0 20px;
        max-width: 20px;
        @media screen and ( max-width: 500px ) {
          display: none;
        }
      }
      .rs-date-picker {
        width: 100%;
        height: 45px;
        text-align: center;
        background: none !important;
        border: none !important;
        @media screen and ( max-width: 500px ) {
          padding-left: 6px;
          padding-right: 6px;
        }

        &:focus-within {
          box-shadow: none;
        }
        input {
          text-align: center;
        }
      }
      .ant-form-item {
        flex-grow: 1;
      }
      .horizontal-divider {
        height: 45px;
        flex: 0 0 20px;
        max-width: 20px;
        display: inline-flex;
        align-items: center;

        &::before {
          content: "";
          display: block;
          width: 100%;
          border-top: 1px solid var(--text-idle);
        }
      }
    }
  }
  .t-range-picker-ctrl {
    height: 45px;
    border-radius: 8px;
    width: 100%;
  }
  .right {
    // width: 36px;
    // height: 36px;
    // border-radius: 8px;
    // border: 1px solid #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 4px;
    height: 45px;

    &.cl-FD6A6A {
      // color: #FD6A6A;
      // border: 1px solid #FD6A6A;

      .ant-btn {
        border-color: #FD6A6A;
      }
    }
    .ant-btn {
      width: 36px;
      height: 36px;
    }
  }
}

.list-check-days {
  gap: 8px;
  margin-bottom: 24px;
  @media screen and ( min-width: 768px ) {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .one-check {
    padding: 0 8px;
    height: 37px;
    border-radius: 8px;
    background-color: #0F0F0F;
    display: flex;
    align-items: center;
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border: 1px solid #999;
        border-radius: 6px;
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #fff;
        border-color: #fff;
        &::after {
          border-color: #4D4D4D;
          width: 7px;
          height: 12px;
        }
      }
    }
    &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
      background-color: #fff;
    }
  }
  span {
    color: #E5E5E5;
    font-size: 15px;
    font-weight: 500;
  }
}

.time-setup-day-block {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.setting-shift {
  .ant-tabs-tab + .ant-tabs-tab {
    margin-inline-start: 0;
  }
  .ant-form,
  .form-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .ant-form {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
.SelectedDay-note {
  display: flex;
  gap: 4px;
  width: 100%;
  justify-content: flex-end;
  color: #999;

  .cl-primary {
    cursor: pointer;
    border-bottom: 1px solid #F9D361;
    color: #F9D361;
  }
  &.disabled {
    cursor: not-allowed;
  }
}

@import "../../styles/root.scss";
.t-fz-16px {
  font-size: 16px;
  line-height: 21px;
}

.t-fz-12px {
  font-size: 12px;
  line-height: 18px;
}

.t-fz-14px {
  font-size: 14px;
  line-height: 20px;
}

.pb-16px {
  padding-bottom: 16px !important;
}

.cl-E5E5E5 {
  color: #e5e5e5 !important;
}

.cl-999999 {
  color: #999999 !important;
}

.t-mw-290 {
  flex: 0 0 auto;
  width: 100%;
  max-width: 290px;
}

.t-table-member {
  font-family: var(--font-inter) !important;

  &.rs-table .ant-table-tbody > tr > th,
  &.rs-table .ant-table-tbody > tr > td,
  &.rs-table tfoot > tr > th,
  &.rs-table .ant-table-thead > tr > th,
  &.rs-table tfoot > tr > td {
    padding: 16px;
  }
  .rs-button {
    background: transparent;
    border-color: transparent;
  }
  &.ant-table-wrapper {
    .ant-table-thead th.ant-table-column-sort {
      background: #262626;
    }
    .ant-table-column-sorter {
      display: none;
    }
    .ant-table-column-sort.ant-table-column-has-sorters {
      .anticon {
        transform: rotate(180deg);
      }
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover {
      background: #262626;
    }
  }
  .ctrl-info-table {
    display: flex;
    gap: 12px;
    align-items: center;
    .img-box {
      flex: 0 0 auto;
      max-width: 40px;
      width: 100%;
    }
    .text {
      flex-grow: 1;
      display: flex;
    }
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none !important;
  }
}

.ctrl-cl-progress.ant-progress {
  .ant-progress-inner {
    background-color: #fff;
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    background: #f9d361;
  }
  .ant-progress-text {
    color: #E5E5E5;
    font-family: var(--font-inter);
    font-size: 14px;
    line-height: 20px;
  }
}

.popup-history {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f9d361;
  }

  .ant-tabs {
    font-family: var(--font-inter);

    .ant-tabs-tab {
      color: #999;
      width: 100%;
      .title-tab {
        display: flex;
        align-items: center;
        gap: 6px;
        .icon-svg {
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-tabs-content {
      color: #999;
    }

    .ant-tabs-ink-bar {
      background: #f9d361;
    }

    .list-normal {
      display: flex;
      gap: 16px;
      flex-direction: column;
      .one-item {
        display: flex;
        gap: 4px;
        border-bottom: 1px solid #262626;
        padding-bottom: 8px;
        .left {
          flex: 0 0 auto;
          max-width: 24px;
          width: 100%;
        }
        .right {
          line-height: 24px;
          flex-grow: 1;
          .item-jcsb {
            display: flex;
            gap: 8px;
            justify-content: space-between;
            // align-items: center;
            .price {
              font-weight: var(--font-bold);
              color: #fff;
            }
            .label {
              white-space: nowrap;
            }
          }
          .img-box {
            margin-top: 12px;
            border-radius: 12px;
            overflow: hidden;
            aspect-ratio: 260/174;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .filter-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 16px;
  }
  &.ant-drawer .ant-drawer-body {
    padding: 0 16px;
  }
}

.px-16px {
  &.ant-drawer .ant-drawer-body {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.t-picker-ctrl {
  .ant-picker-input {
    border-color: var(--grey-900);
    background-color: var(--grey-900);
    color: var(--text-idle);
    padding-left: 44px;
    padding-right: 16px;
    padding-block: 4px;
    height: 40px;
    position: relative;
    border-radius: 8px;

    &:hover {
      border-color: var(--grey-800);
      background-color: var(--grey-800);
    }

    input {
      &::placeholder {
        color: var(--text-idle) !important;
      }
    }

    .anticon {
      color: var(--text-idle);
    }

    .ant-picker-suffix,
    .ant-picker-clear {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      margin-inline-start: 0;
      font-size: 20px;
      color: var(--text-idle);
    }
  }

  &.ant-picker {
    padding-block: 0 !important;
    padding: 0 !important;
    border-color: transparent !important;
    background: transparent !important;
  }
}

.voucher-type-status {
  padding-left: 20px;
  position: relative;
  color: #0CAF60;
  &::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0CAF60;
    color: #0CAF60;
  }

  &.type-2 {
    color: #F9D361;
    &::before {
      background-color: #F9D361;
    }
  }

  &.type-3 {
    color: #175CD3;
    &::before {
      background-color: #175CD3;
    }
  }
}

.voucher-user-info {
  display: flex;
  gap: 12px;
  align-items: center;
  .img-box {
    flex: 0 0 auto;
    max-width: 40px;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .iduser {
    color: #667085;
  }
}

.rs-tabs {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;

  .ant-tabs {
    &-tab {
      font-size: 16px;
      line-height: 22px;
      color: var(--text-idle);
      padding: 6px 18px 7px;
      min-width: 120px;
      justify-content: center;

      + .ant-tabs-tab {
        margin: 0;
      }
      &:hover,
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--text-active);
      }
    }
    &-ink-bar {
      background: var(--text-active);
    }
  }
  &.ant-tabs-top {
    .ant-tabs-ink-bar {
      height: 1px;
    }
    > .ant-tabs-nav::before {
      border-block-end-color: var(--grey-800);
    }
  }
  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
  &.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    color: var(--text-idle);
    background: var(--grey-900);
    padding-inline: 8px;
    font-size: 16px;
    border-radius: 8px;
  }

  > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    box-shadow: inset -10px 0 8px -8px rgba(153, 153, 153, 0.08);
  }
  &--no-tab-content {
    .ant-tabs-content-holder {
      display: none;
    }
  }
  &-dropdown {
    font-family: var(--font-regular);
    color: var(--text-idle);

    .ant-tabs-dropdown-menu {
      background: var(--grey-900);
      box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
      border: 1px solid #F2F4F7;
    }
    .ant-tabs-dropdown-menu-item {
      color: inherit;

      &:hover {
        background: var(--grey-800);
      }
    }
  }
}

.rs-modal {
  font-family: inherit;
  color: var(--text-idle);

  .ant-modal {
    &-content {
      padding: 0;
      background: #000000;
      box-shadow: 0 0 1px var(--text-light);
    }
    &-header {
      margin-block-end: 0;
    }
    &-title {
      font-family: inherit;
      color: var(--text-idle);
      font-weight: normal;
    }
    &-body {
      padding: 24px 32px;
      font-size: 16px;
      font-family: inherit;

      .ant-form {
        width: 100%;
      }
    }
    &-footer {
      margin-block-start: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-btn {
        font-family: inherit;
        font-weight: 700;
      }
    }
    &-close {
      width: auto;
      height: auto;
      font-weight: normal;

      &:hover {
        color: var(--text-idle);
        background: transparent;
      }
    }
  }
  .ant-form {
    display: flex;
    flex-direction: column;
    padding-block-end: 8px;

    &-item {
      margin-block-end: 0;

      &-label > label {
        color: var(--text-light);
      }
    }
  }
  .w-240 {
    width: 240px;
    margin-inline: auto;
  }
  &.modal-delete {
    .content {
      white-space: pre-wrap;
    }
    .ant-btn {
      width: 227px;
    }
  }
  &.w-360 {
    width: 362px !important;
  }
  &.w-687 {
    width: 687px !important;
  }
  &.modal-payment {
    .ant-form {
      &-item {
        &-label {
          flex: 0 0 120px;
          max-width: 120px;
        }
      }
    }
  }
  &-root {
    font-family: inherit;

    .ant-modal {
      font-family: inherit;

      &-mask {
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);
      }
    }
  }
}

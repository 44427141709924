@import './_fonts';
@import './_color';
@import './_variables';
@import './_rs-ant';
@import './_rs-slick';
@import './_rs-recharts';
@import './ARCCard';

::-webkit-scrollbar {
  border-radius: var(--scrollbar-radius, 8px);
  background: var(--scrollbar-bg, var(--grey-800));
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: var(--scrollbar-thumb-radius, 8px);
  background: var(--scrollbar-thumb-bg, var(--grey-600));
  transition: none !important;
}
::-webkit-scrollbar-thumb:hover{
  background: var(--scrollbar-thumb-hover-bg, var(--grey-600));
}
input::placeholder {
  color: var(--text-idle);
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
  &-move {
    cursor: move;
  }
}

.flex {
  display: flex;

  &-vertical {
    flex-direction: column;
  }
}

// TABLE FILTER BLOCK
.table-filter-block {
  display: flex;
  // align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-block-end: 18px;

  > * {
    min-width: 100px;
  }
  .left,
  .right {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .right {
    margin-inline-start: auto;
  }
  .mis-auto {
    margin-inline-start: auto;
  }
  .w {
    &-120 {
      width: 120px;
    }
    &-186 {
      width: 186px;
    }
    &-214 {
      width: 214px;
    }
    &-290 {
      width: 290px;
    }
    &-349 {
      width: 349px;
    }
  }
  .mw-100-767 {
    @media screen and ( max-width: 767px ) {
      max-width: 100% !important;
    }
  }
  .mw {
    &-186 {
      flex-grow: 1;
      max-width: 186px;
    }
    &-173 {
      flex-grow: 1;
      max-width: 173px;
    }
    &-208 {
      flex-grow: 1;
      max-width: 208px;
    }
    &-214 {
      flex-grow: 1;
      max-width: 214px;
    }
    &-288 {
      flex-grow: 1;
      max-width: 288px;
    }
    &-290 {
      flex-grow: 1;
      max-width: 290px;
    }
    &-349 {
      flex-grow: 1;
      max-width: 349px;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &__search {
    flex-grow: 1;
    // max-width: 349px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    &__search {
      max-width: 349px;
    }
  }
}
.text {
  &-nowrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &-success {
    color: var(--text-success);
  }
  &-light {
    color: var(--text-light);
  }
}

.title {
  &-12 {
    font-size: 12px;
  }
  &-20 {
    font-size: 20px;
  }
  &-24 {
    font-size: 24px;
  }
  &-32 {
    font-size: 32px;
  }
}

.gap {
  &-8 {
    gap: 8px;
  }
  &-12 {
    gap: 12px;
  }
  &-24 {
    gap: 24px;
  }
}

.fw {
  &-600 {
    font-weight: 600;
  }
  &-700 {
    font-weight: 700;
  }
}

.mis-auto {
  margin-inline-start: auto;
}

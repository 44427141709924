.sales-report-content {
  .metric {
    &-item {
      display: flex;
      height: 40px;
      min-width: 88px;
      color: var(--text-idle);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media screen and ( max-width: 470px ) {
        min-width: 70px;
      }

      &.active {
        color: var(--text-light);
        background: var(--grey-900);
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px #1018280D;
      }
    }
  }
  .sales-report-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-columns: 1fr;
    gap: 24px;
    @media screen and ( max-width: 767px ) {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
  }
  .d-colums-767 {
    @media screen and ( max-width: 767px ) {
      flex-wrap: wrap;
      gap: 12px !important;
      align-items: start;
    }
  }
}

.rs-steps {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  --icon-height: 40px;
  --icon-tail-height: 2px;

  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: inherit;
  }
  .ant-steps-item-custom >.ant-steps-item-container>.ant-steps-item-icon >.ant-steps-icon {
    height: var(--icon-height);
    width: auto;
    aspect-ratio: 1;
    border: 2px solid var(--text-idle);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--text-idle);
    background: #000;
  }
  .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon >.ant-steps-icon,
  .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon >.ant-steps-icon {
    color: var(--text-active);
    border-color: var(--text-active);
  }
  .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: var(--text-idle);
  }
  .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after,
  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #CFD6DC;
  }
  .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: var(--text-active);
  }
  &.ant-steps-label-vertical .ant-steps-item-tail {
    padding-block: 0;
    top: calc(var(--icon-height) / 2);
    line-height: 0;
    padding-inline-end: 14px;
    transform: translateY(calc(var(--icon-tail-height) / -2));
    height: var(--icon-tail-height);

    &::after {
      height: var(--icon-tail-height);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  &.ant-steps-label-vertical .ant-steps-item-icon {
    margin-inline-start: 32px;
  }
  &.ant-steps-label-vertical .ant-steps-item-content .ant-steps-item-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
}

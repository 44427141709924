.role-permission-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  gap: 8px;
  color: var(--text-light);
  background: var(--grey-800);

  .anticon {
    color: var(--text-idle);
  }
  .title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action {
    &s {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-inline-start: auto;
    }
    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      @media screen and ( max-width: 500px ) {
        span {
          display: none;
        }
      }

      &.delete {
        color: var(--dust-red-6);
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}

.privacy-policy-page {
  font-size: 16px;
  line-height: initial;

  .container {
    width: 100vw;
    max-width: 1000px;
    padding-block: 48px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #B5B5B5;

    h1,
    h3 {
      color: #E5E5E5;
    }
  }
}

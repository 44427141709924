.rs-table {
  font-family: inherit;
  font-size: 14px;
  line-height: inherit;
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;

  .ant-image{
    border-radius: 50%;
    overflow: hidden;
    .ant-image-mask-info{
      font-size: 0;
      display: flex;
    }
    .anticon.anticon-eye{
      font-size: 14px;
      margin-right: 0;
    }
  }

  &.table-text-nowrap {
    white-space: nowrap;
  }
  &.match-height {
    // flex-grow: 1;
    // display: flex;
    // flex-direction: column;

    // .ant-table,
    // .ant-table-container,
    // .ant-table-content,
    // .ant-spin-nested-loading,
    // .ant-spin-nested-loading .ant-spin-container {
    //   flex-grow: 1;
    //   display: flex;
    //   flex-direction: column;
    // }
    // .ant-table-pagination {
    //   margin-block-start: auto;
    // }
    // .ant-table {
    //   overflow-y: auto;
    //   background: var(--grey-900);
    // }

    // height: 100%;

    // .ant-spin-nested-loading {
    //   height: 100%;

    //   .ant-spin-container {
    //     height: 100%;
    //     display: flex;
    //     flex-flow: column nowrap;

    //     .ant-table {
    //       flex: auto;
    //       overflow: hidden;

    //       .ant-table-container {
    //         height: 100%;
    //         display: flex;
    //         flex-flow: column nowrap;

    //         .ant-table-header {
    //           flex: none;
    //         }

    //         .ant-table-body {
    //           flex: auto;
    //           overflow: scroll;
    //         }
    //       }
    //     }

    //     .ant-table-pagination {
    //       flex: none;
    //     }
    //   }
    // }
  }

  .ant-table {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background: transparent;
  }
  .ant-table-thead >tr>th,
  .ant-table-thead >tr>td {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    padding: 12px 24px;
    color: var(--text-light);
    background: var(--grey-800);
    border-block-end-color: var(--grey-800);
    white-space: nowrap;

    &::before {
      display: none;
    }
  }
  .ant-table-tbody>tr>th,
  .ant-table-tbody>tr>td,
  tfoot>tr>th,
  tfoot>tr>td {
    padding: 16px 24px;
    color: var(--text-light);
    background-color: var(--grey-900);
    border: none;
    box-shadow: inset 0 -1px 0 var(--grey-800);

    &.actions-sticky,
    &.ant-table-selection-column {
      box-shadow: inset 0 -1px 0 var(--grey-800), 0px 12px 24px 0px #000000;
    }
  }
  // .ant-table-tbody>tr>th,
  // .ant-table-tbody>tr>td {
  //   max-width: 240px;
  //   white-space: normal;
  // }
  .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell {
    background: #151515;
  }
  .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover,
  .ant-table-tbody>tr.ant-table-placeholder,
  .ant-table-tbody>tr.ant-table-placeholder:hover>td {
    // background-color: rgba(255, 255, 255, .09);
    background-color: #1a1a1a;
  }
  .ant-table-scroll-horizontal .ant-table-content {
    overflow-x: auto !important;
    background: #8E8E8E;
    border-radius: 8px 8px 0 0;
  }
  .actions-sticky {
    .ant-btn-icon-only {
      background: transparent;
      height: 40px;
      width: auto;
      aspect-ratio: 1;
      padding: 0;
      align-items: center;
      justify-content: center;
      color: var(--text-idle);
      border: none;
      box-shadow: none;

      .anticon {
        font-size: 20px;
      }
      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: transparent;
      }
    }
    .delete {
      color: var(--text-alert);

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: var(--text-alert);
      }
    }
  }
  .ant-empty-normal .ant-empty-description {
    color: var(--text-idle);
  }
  .ant-table-summary {
    tr > td {
      padding-inline: 24px;
    }
  }

  // PAGINATION
  .ant-pagination {
    font-size: inherit;
    font-family: inherit;
    background: var(--grey-800);
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 2px;

    &.ant-table-pagination {
      margin-block: 0;
      border-end-start-radius: 8px;
      border-end-end-radius: 8px;
    }
    .ant-pagination-item {
      background: transparent;

      a {
        color: var(--text-idle);
        padding-inline: 4px;
      }
      &-active {
        color: var(--text-light);
        border-color: var(--text-idle);
        background-color: transparent;
      }
    }
    .ant-pagination-item:not(.ant-pagination-item-active):hover,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-prev:active .ant-pagination-item-link,
    .ant-pagination-next:active .ant-pagination-item-link {
      color: var(--text-light);
      background: transparent;
    }
    .ant-pagination-options,
    .ant-pagination-item {
      margin-inline: 0;
    }
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next,
    .ant-pagination-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-family: inherit;
      font-size: inherit;
      font-weight: 500;
    }
    .ant-pagination-item {
      height: 40px;
      width: auto;
      aspect-ratio: 1;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      color: var(--text-idle);

      button {
        font-size: 20px;
        color: var(--text-idle);
      }
    }
    .ant-pagination-prev {
      margin-inline-end: auto;
    }
    .ant-pagination-next {
      order: 1000;
      margin-inline-start: auto;
    }

    // PAGE SIZER
    .ant-pagination-options-size-changer {
      color: inherit;
      font-family: inherit;
      line-height: inherit;
      height: auto;

      .ant-select-selector {
        padding-block: 4px;
        color: var(--text-idle);
        background: transparent;
        border-color: var(--text-idle);
      }
      .ant-select-arrow {
        color: var(--text-idle);
      }
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: var(--text-idle);
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      font-size: inherit;
      color: var(--text-idle);
    }
  }
}

@font-face {
  font-family: 'Satoshi-Regular';
  src: url('../fonts/satoshi/Satoshi-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Satoshi-Light';
  src: url('../fonts/satoshi/Satoshi-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Satoshi-Medium';
  src: url('../fonts/satoshi/Satoshi-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Satoshi-Bold';
  src: url('../fonts/satoshi/Satoshi-Bold.otf') format('opentype');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.font {
  &-light {
    font-family: var(--font-light);
  }
  &-regular {
    font-family: var(--font-regular);
  }
  &-medium {
    font-family: var(--font-medium);
  }
  &-bold {
    font-family: var(--font-bold);
  }
  &-inter {
    font-family: var(--font-inter);
  }
}

.game-bar {
  border: thin solid var(--text-active);
  display: flex;
  flex-direction: column;
  background: var(--grey-900);
  border-radius: 16px;
  overflow: hidden;

  &__header,
  &__footer {
    box-shadow: 0px 12px 120px 0px #000000;
    background-repeat: no-repeat;
    background-position-x: center;
  }
  &__header {
    height: 127px;
    background-position-y: -3px;
    text-align: center;
    padding-block-start: 36px;

    &-logo {
      height: 34px;
      width: 54px;
    }
  }
  &__content {
    flex-grow: 1;
    padding-block-start: clamp(40px, 9vh, 98px);
    text-align: center;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 48px;
      background: -webkit-linear-gradient(#FFE279, #F2C142);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      line-height: 64px;
      margin-block-end: 27px;
    }
    .announcement {
      height: 80px;
      width: 306px;
      display: inline-flex;
      background: radial-gradient(50% 50% at 50% 50%, rgba(177, 177, 177, 0) 47.4%, rgba(177, 177, 177, 0.08) 100%);
      border-radius: 15px;
      align-self: center;

      &-text {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--text-light);
        flex-grow: 1;

        .name {
          font-size: 24px;
          line-height: 32px;
        }
        .id {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .separator {
      margin-block: clamp(20px, 3.5vh, 38px);
    }
    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: clamp(10px, 2vw, 24px);

      &--2 {
        margin-block: auto clamp(20px, 3.5vh, 38px);
      }
    }
  }
  &__footer {
    height: 84px;
    background-position-y: bottom;
  }

  @media screen and (min-width: 1024px) {
    &__content {
      .controls--2 {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    flex: 0 1 436px;
  }
}

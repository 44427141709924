.match-chart {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-block: 24px;
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A;
  background: var(--grey-900);
  border-radius: 8px;

  .title {
    padding-inline: 48px;
    font-weight: 700;
  }
}

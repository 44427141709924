.choose-store {
  &-page {
    padding-inline: 10px;
    padding-block: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    @media screen and ( max-width: 1199px ) {
     justify-content: start;
    }

    .go-back {
      padding-inline: 20px;
      align-self: start;

      &:not(:hover) {
        color: var(--text-idle);
        border-color: var(--text-idle);
        background: transparent;
      }
    }
    .title {
      font-size: 40px;
      line-height: 54px;
      color: var(--text-active);
      @media screen and ( max-width: 1199px ) {
        font-size: 24px;
        line-height: 1.4;
      }
    }
    &-container {
      width: 100%;
      max-width: 1440px;
      margin-inline: auto;
      display: flex;
      flex-direction: column;
      gap: 64px;
      @media screen and ( max-width: 1199px ) {
        gap: 24px;
      }
    }
  }
}

.rs-input {
  font-family: inherit;
  line-height: inherit;
  color: var(--text-light);

  .ant-input-number-input {
    font-family: inherit;
    line-height: inherit;
    color: var(--text-light);
  }
  &.ant-input-number-outlined {
    border: none;
    background: transparent;
  }
  .ant-input-outlined,
  &.ant-input-outlined,
  .ant-input-number-input,
  &.ant-input-number-affix-wrapper {
    background: var(--input-bg, var(--grey-900));
    border-color: var(--input-bg, var(--grey-900));
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  .ant-input-affix-wrapper,
  &.ant-input-affix-wrapper {
    color: var(--input-color, var(--text-idle));
    padding-block: 8px;

    .ant-input-prefix {
      margin-inline-end: 8px;
    }
  }
  &::placeholder,
  input::placeholder,
  textarea::placeholder,
  .ant-input-number-input::placeholder {
    color: var(--input-placeholder-color, var(--text-idle));
  }
  &.ant-input-group-wrapper-outlined .ant-input-group-addon {
    border-color: var(--grey-900);
    background: var(--grey-900);
    border-inline-start: 2px solid var(--grey-800);
  }
  .ant-input-clear-icon {
    color: var(--text-idle);
    font-size: 14px;

    &:hover {
      color: var(--text-light);
    }
  }
  &.ant-input-outlined.ant-input-disabled,
  &.ant-input-outlined[disabled] {
    color: var(--text-light);
  }
  &.ant-input-textarea-show-count {
    margin-block-end: 20px;

    .ant-input-data-count {
      color: var(--text-light);
      font-size: 12px;
    }
  }
  &.ant-input-number-outlined.ant-input-number-status-error:not(.ant-input-number-disabled),
  &.ant-input-status-error:not(.ant-input-disabled) {
    background: none;
    border-color: var(--text-alert);

    &:hover,
    &:focus,
    &:focus-within {
      background: none;
      border-color: var(--text-alert-base);
    }
  }
  &.ant-input-number-affix-wrapper {
    width: 100%;
  }
}

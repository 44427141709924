.add-player-modal {
  width: 1200px !important;

  .table-filter-block {
    padding-block-start: 24px;

    .left {
      > * {
        // flex: 0 0 223px;
        width: 223px;
      }
    }
    .right {
      .search-group {
        // flex: 0 0 349px;
        width: 349px;
      }
      .ant-btn {
        // flex-basis: 186px;
        width: 186px;
      }
    }
  }
}

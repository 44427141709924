.payment-method-item {
  background: linear-gradient(108.38deg, #262626 -8.18%, #191A1D 158.56%);
  border-radius: 12px;
  padding: 48px;
  min-height: 207px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  overflow: hidden;

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--text-light);

    .title {
      font-size: 18px;
      line-height: 22px;
    }
    .number {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-block-start: auto;

    img {
      max-height: 16px;
    }
    .default {
      color: var(--text-active);
    }
  }
  .controls {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.41);
    backdrop-filter: blur(6px);
    transition: all .25s;
  }
  &:not(:hover) {
    .controls {
      opacity: 0;
      visibility: hidden;
    }
  }
}

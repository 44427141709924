:root {
  --font-light: 'Satoshi-light';
  --font-regular: 'Satoshi-Regular';
  --font-medium: 'Satoshi-Medium';
  --font-bold: 'Satoshi-Bold';
  --font-inter: 'Inter';

  --grey-50: #FDFDFD;
  --grey-600: #666666;
  --grey-700: #4D4D4D;
  --grey-800: #262626;
  --grey-900: #0F0F0F;
  --text-light: #E5E5E5;
  --text-idle: #999999;
  --text-dark: #0F0F0F;
  --text-alert: #FF1D1D;
  --text-alert-base: #FD6A6A;
  --text-active: #F9D361;
  --text-red-3: #FFA39E;
  --text-success: #0CAF60;

  --neutral-gray-100: #F2F4F7;
  --neutral-gray-200: #EAECF0;
  --neutral-gray-300: #D0D5DD;
  --neutral-gray-400: #98A2B3;
  --neutral-gray-500: #667085;
  --neutral-gray-700: #344054;

  --primary-1: #E6F7FF;
  --primary-3: #D0EEFF;
  --primary-5: #40A9FF;
  --primary-6: #1890FF;
  --primary-7: #096DD9;
  --daybreak-blue-3: #91D5FF;

  --polar-green: #52C41A;
  --polar-green-1: #F6FFED;
  --polar-green-3: #B7EB8F;
  --polar-green-6: #52C41A;

  --success-25: #F6FEF9;
  --success-500: #12B76A;
  --success-600: #039855;

  --golden-purple-1: #F9F0FF;
  --golden-purple-3: #D3ADF7;
  --golden-purple-5: #9254DE;
  --golden-purple-6: #722ED1;

  --sunset-orange-1: #FFF7E6;
  --sunset-orange-3: #FFD591;
  --sunset-orange-6: #FA8C16;

  --volcano-1: #FFF2E8;
  --volcano-5: #FF7A45;
  --volcano-6: #FA541C;

  --magenta-1: #FFF0F6;
  --magenta-5: #F759AB;
  --magenta-6: #EB2F96;

  --geek-blue-1: #F0F5FF;
  --geek-blue-3: #ADC6FF;
  --geek-blue-6: #2F54EB;

  --dust-red-1: #FFF1F0;
  --dust-red-3: #FFA39E;
  --dust-red-4: #FF7875;
  --dust-red-5: #FF4D4F;
  --dust-red-6: #F5222D;

  --blue-700: #175CD3;
}

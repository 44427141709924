@function vw($n, $screen) {
  @if $screen == 0 {
    @return #{$n}px;
  } @else {
    @return #{calc(($n * 100) / 1440)}vw;
  }
}

$screens: (
  xs: 0,
  md: 1200
);

@mixin vwConvert() {
  @each $size, $screen in $screens {
    @if $screen != 0 {
      @media screen and (min-width: #{$screen}px) {
        @content($screen)
      }
    } @else {
      @content($screen)
    }
  }
}
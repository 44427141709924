@include vwConvert() using ($s) {
  .rs-timeline {
    &.ant-timeline-label {
      .ant-timeline-item {
        padding-block-end: vw(28, $s);
      }
      .ant-timeline-item-left {
        .ant-timeline-item-label {
          width: vw(94, $s);
          padding-block-start: vw(4, $s);
        }
        .ant-timeline-item-content {
          width: calc(100% - vw(94, $s));
          inset-inline-start: vw(94, $s);
          margin-inline-start: vw(48, $s);
        }
      }
      .ant-timeline-item-head-custom {
        inset-inline-start: vw(94, $s);
        height: vw(32, $s);
        width: vw(32, $s);

      }
      .ant-timeline-item-tail {
        height: 100%;
        inset-inline-start: calc(vw(94, $s) + vw(16, $s));
        border-inline-width: vw(2, $s);
      }
    }
    .timeline {
      &-dot {
        height: vw(32, $s);
        width: vw(32, $s);
        font-size: vw(18, $s);
      }
      &-content {
        line-height: vw(22, $s);
        padding-block-start: vw(4, $s);

        .title {
          font-size: vw(16, $s);
          line-height: vw(24, $s);
          margin-block-end: vw(2, $s);
        }
      }
    }
  }
}

.rs-timeline {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  color: inherit;

  &.ant-timeline-label {
    .ant-timeline-item {
      font-size: inherit;
    }
    .ant-timeline-item-label {
      inset-block-start: 0;
      text-align: start;
    }
    .ant-timeline-item-head-custom {
      transform: translateY(0%);
      padding: 0;
      inset-block-start: 0;
    }
    .ant-timeline-item-content {
      inset-block-start: 0;
    }
    .ant-timeline-item-tail {
      inset-block-start: 0;
      border-inline-color: var(--neutral-gray-200);
    }
  }
  .timeline {
    &-dot {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &--success {
        color: #039855;
        background: #ECFDF3;
      }
      &--primary {
        color: #096DD9;
        background: #E6F7FF;
      }
      &--magenta {
        color: #F759AB;
        background: #FFF0F6;
      }
      &--purple {
        color: #9254DE;
        background: #F9F0FF;
      }
      &--volcano {
        color: #FF7A45;
        background: #FFF2E8;
      }
    }
    &-content {
      .title {
        font-family: var(--font-medium);
        color: var(--neutral-gray-600)
      }
    }
  }
}

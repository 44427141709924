@include vwConvert() using ($s) {
  .rs-slick.slick-slider {
    .slick {
      &-list {
        margin-inline: vw(-6, $s);
      }
      &-slide {
        padding-inline: vw(6, $s);
      }
      &-dots {
        gap: vw(8, $s);

        li {
          border-radius: vw(2, $s);
          height: vw(3, $s);
          width: vw(16, $s);

          &.slick-active {
            width: vw(24, $s);
          }
        }
      }
    }
    &.dots-outer {
      .slick {
        &-dots {
          margin-block-start: vw(12, $s);
        }
      }
    }
  }
}

.rs-slick.slick-slider {
  .slick {
    &-dots {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex !important;
      justify-content: center;
      align-items: center;

      button {
        position: absolute;
        inset: 0;
        opacity: 0;
      }
      li {
        position: relative;
        background: var(--neutral-gray-400);
        transition: all .25s;

        &.slick-active {
          background: var(--primary-6);
        }
      }
    }
  }
}

@import '../../styles/root.scss';
.f-mw-288px {
  max-width: 288px;
  width: 100%;
  flex: 0 0 auto;
}

.f-mw-208px {
  max-width: 208px;
  width: 100%;
  flex: 0 0 auto;
}

.f-mw-223px {
  max-width: 223px;
  width: 100%;
  flex: 0 0 auto;
}

.f-mw-208px {
  max-width: 208px;
  width: 100%;
  flex: 0 0 auto;
}

.mw-345px {
  max-width: 345px;
  width: 100%;
  flex: 0 0 auto;
}

.t-input-ctrl {
  border-color: var(--grey-900);
  background-color: var(--grey-900);
  color: var(--text-idle);
  padding-inline: 16px;
  padding-block: 4px;
  height: 40px;
  &.mw-349px {
    width: 349px;
    flex: 0 0 auto;
  }
  &:hover {
    border-color: var(--grey-800);
    background-color: var(--grey-800);
  }
}

.t-h40 {
  height: 40px !important;
}

.t-Pagination {
  padding: 12px 0;
  background-color: #262626;
  position: relative;
  .ant-pagination {
    text-align: center;

    .ant-pagination-item a {
      color: #E5E5E5;
    }

    .ant-pagination-item-active {
      background-color: transparent;
      border-color: #E5E5E5;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      color: #E5E5E5;
    }

    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
      color: #E5E5E5;
    }

    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: #E5E5E5;
    }

    .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #E5E5E5;
      background: transparent;
      color: #E5E5E5;
    }

    .ant-select .ant-select-arrow {
      color: #E5E5E5;
    }

    a[disabled] {
      color: #E5E5E5;
    }

    .ant-pagination-prev, .ant-pagination-next {
      position: absolute;
      top: 12px;

      p {
        display: flex;
        gap: 8px;
        align-items: center;
        color: #999;
      }
    }

    .ant-pagination-prev {
      left: 24px;
    }

    .ant-pagination-next {
      right: 24px;
    }
  }
}

.ctrl-td-final {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
}

.t-range-picker-ctrl {
  border-color: var(--grey-900);
  background-color: var(--grey-900);
  color: var(--text-idle);
  padding-left: 44px !important;
  padding-right: 16px !important;
  padding-block: 4px !important;
  height: 40px;
  position: relative;
  &:hover {
    border-color: var(--grey-800);
    background-color: var(--grey-800);
  }
  input {
    &::placeholder {
      color: var(--text-idle) !important;
    }
  }
  .ant-picker-separator {
    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #999999;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      position: absolute;
    }
    .anticon {
      display: none;
    }
  }
  .anticon {
    color: var(--text-idle);
  }
  .ant-picker-suffix, .ant-picker-clear {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    margin-inline-start: 0;
    font-size: 20px;
    color: var(--text-idle);
    inset-inline-end: auto;
  }
  .ant-picker-input>input:placeholder-shown {
    text-align: center;
  }
  &.ant-picker .ant-picker-input>input {
    text-align: center;
  }
}

.t-table-block {

  .ant-table-wrapper {
    .ant-table {
      background-color: #0F0F0F;
      color: #E5E5E5;
      font-family: var(--font-inter);
    }
    .ant-table-tbody>tr>td {
      border-bottom: 1px solid #262626;
    }
    .ant-table-thead>tr>th,
    .ant-table-thead>tr>td {
      position: relative;
      color: #E5E5E5;
      font-weight: 600;
      text-align: start;
      background: #262626;
      border-bottom: 1px solid #262626;
    }
    .ant-table-thead>tr>th {
      white-space: nowrap;
    }
    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
    .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
    .ant-table-cell-scrollbar:not([rowspan]) {
      box-shadow: none;
    }
    .ant-table-tbody .ant-table-row>.ant-table-cell-row-hover {
      background: #262626;
    }
    .ant-table-summary {
      box-shadow: none;
      .ant-table-cell {
        position: relative;
        color: #E5E5E5;
        font-weight: 600;
        text-align: start;
        background: #262626;
        border-bottom: 1px solid #4D4D4D;
        text-align: right;
        &:first-child {
          text-align: left;
        }
        &.t-total-table {
          padding-right: 32px;
          color: #55C790;
        }
      }
    }
    .ctrl-td-profit {
      .status-1 {
        color: #55C790;
      }
      .status-2 {
        color: #FD8181;
      }
    }
  }
}

.report-machine-income-content {
  .table-filter-block .left {
    gap: 12px;
    // justify-content: space-between;
    flex-grow: 1;

  }
  .filter-search-box {
    max-width: 349px;
  }
}

.report-staff-content {
  .table-filter-block {
    .filter-search-box {
      max-width: 349px;
    }
  }
}
